import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  setDoc,
  query,
  where,
  addDoc,
} from "firebase/firestore";

const LeagueApplications = () => {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState(null);
  const [leagueId, setLeagueId] = useState(null);
  const [userName, setuserName] = useState(null);

  useEffect(() => {
    // Bekleyen başvuruları çekme
    const fetchApplications = async () => {
      try {
        // Başvurular, kullanıcılar ve ligler için snapshot'ları alın
        const applicationsSnapshot = await getDocs(
          collection(db, "leagueApplications")
        );
        const usersSnapshot = await getDocs(collection(db, "users"));
        const leaguesSnapshot = await getDocs(collection(db, "leagues"));

        // Kullanıcıları ve ligleri nesneye çevirerek ID -> veri eşleşmesini sağla
        const usersMap = {};
        usersSnapshot.docs.forEach((doc) => {
          usersMap[doc.id] = doc.data();
        });

        const leaguesMap = {};
        leaguesSnapshot.docs.forEach((doc) => {
          leaguesMap[doc.id] = doc.data().name; // Lig adı "name" olarak varsayılıyor
        });

        // Başvuruları işle ve kullanıcı/lig bilgilerini ekle
        const apps = applicationsSnapshot.docs
          .map((doc) => {
            const data = doc.data();
            const userName = usersMap[data.userId]
              ? `${usersMap[data.userId].firstName} ${
                  usersMap[data.userId].lastName
                }`
              : "Bilgi Yok";
            setuserName(userName);
            const leagueName = leaguesMap[data.leagueId] || "Bilgi Yok"; // Lig adını ekle
            setLeagueId(data.leagueId);

            return {
              id: doc.id,
              userName,
              leagueName,
              status: data.status,
              userId: data.userId,
              leagueId: data.leagueId,
            };
          })
          .filter((app) => app.status === "pending"); // Sadece "pending" başvuruları filtrele

        setApplications(apps);
      } catch (err) {
        console.error("Hata:", err);
        setError("Başvurular çekilirken bir hata oluştu.");
      }
    };

    fetchApplications();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  // Başvuru Onaylama Fonksiyonu
  const handleApprove = async (applicationId, userId, leagueId) => {
    try {
      // Kullanıcının zaten bu lige kayıtlı olup olmadığını kontrol et
      const existingParticipantSnapshot = await getDocs(
        query(
          collection(db, "leagueParticipants"),
          where("leagueId", "==", leagueId),
          where("userId", "==", userId)
        )
      );

      if (!existingParticipantSnapshot.empty) {
        alert("Bu kullanıcı zaten bu lige kayıtlı.");
        return;
      }

      // Mevcut katılımcı sayısını öğren
      const participantsSnapshot = await getDocs(
        query(
          collection(db, "leagueParticipants"),
          where("leagueId", "==", leagueId)
        )
      );
      const rank = participantsSnapshot.size + 1;

      // Kullanıcıyı leagueParticipants koleksiyonuna ekle
      await setDoc(doc(db, "leagueParticipants", `${leagueId}_${userId}`), {
        leagueId,
        userId,
        joinDate: new Date(),
        rank,
        status: "added",
      });

      // Başvuru durumunu güncelle
      await updateDoc(doc(db, "leagueApplications", applicationId), {
        status: "confirmed",
      });

      // Admin log kaydı ekle
      await addDoc(collection(db, "adminLogs"), {
        eventType: "ApplicationApproved",
        eventDetails: `Kullanıcı ${userId} ${leagueId} ligine katılımı onaylandı.`,
        createdAt: new Date(),
        status: "unread",
      });

      alert("Başvuru onaylandı!");
      // Onaylanan başvuruyu listeden çıkar
      setApplications((prevApplications) =>
        prevApplications.filter((app) => app.id !== applicationId)
      );
    } catch (error) {
      console.error("Başvuru onaylama hatası:", error);
      alert("Başvuru onaylanırken bir hata oluştu.");
    }
  };

  // Başvuru Reddetme Fonksiyonu
  const handleReject = async (applicationId) => {
    try {
      // Başvuru durumunu rejected olarak güncelle
      await updateDoc(doc(db, "leagueApplications", applicationId), {
        status: "rejected",
      });

      // Admin log kaydı ekle
      await addDoc(collection(db, "adminLogs"), {
        eventType: "ApplicationRejected",
        eventDetails: `${leagueId} Ligine yapılan ${applicationId} numaralı başvuru reddedildi.`,
        createdAt: new Date(),
        status: "unread",
      });

      alert("Başvuru reddedildi!");
      // Reddedilen başvuruyu listeden çıkar
      setApplications((prevApplications) =>
        prevApplications.filter((app) => app.id !== applicationId)
      );
    } catch (error) {
      console.error("Başvuru reddetme hatası:", error);
      alert("Başvuru reddedilirken bir hata oluştu.");
    }
  };

  return (
    <div>
      <h3>Bekleyen Lig Başvuruları</h3>
      {applications.length === 0 ? (
        <p>Bekleyen başvuru bulunmamaktadır.</p>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Kullanıcı Adı</th>
              <th>Lig Adı</th>
              <th>Durum</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((app, index) => (
              <tr key={app.id}>
                <td>{index + 1}</td>
                <td>{app.userName}</td>
                <td>{app.leagueName}</td>
                <td>{app.status}</td>
                <td>
                  <button
                    onClick={() =>
                      handleApprove(app.id, app.userId, app.leagueId)
                    }
                  >
                    Onayla
                  </button>
                  <button onClick={() => handleReject(app.id)}>Reddet</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LeagueApplications;
