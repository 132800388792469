import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import ShowModal from "../component/ShowModal1";

function AdminNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState("unread"); // unread, all, read, deleted
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const [eventType, setEventType] = useState("all");
  const [eventTypes, setEventTypes] = useState([]);

  // Fetch available event types for filtering
  const fetchEventTypes = async () => {
    try {
      const notificationsCollection = collection(db, "adminLogs");
      const notificationsSnapshot = await getDocs(notificationsCollection);
      const types = new Set();
      notificationsSnapshot.forEach((doc) => {
        types.add(doc.data().eventType);
      });
      setEventTypes(["all", ...Array.from(types)]);
    } catch (error) {
      console.error("Etkinlik türleri getirilirken hata oluştu:", error);
    }
  };

  // Fetch notifications from Firestore
  const fetchNotifications = async () => {
    try {
      const notificationsCollection = collection(db, "adminLogs");
      let filters = [];

      if (filter === "unread") {
        filters.push(where("status", "==", "unread"));
      } else if (filter === "read") {
        filters.push(where("status", "==", "read"));
      } else if (filter === "deleted") {
        filters.push(where("status", "==", "deleted"));
      }

      if (eventType !== "all") {
        filters.push(where("eventType", "==", eventType));
      }

      const notificationsQuery = query(
        notificationsCollection,
        ...filters,
        orderBy("createdAt", "desc")
      );

      // console.log("Sorgu Filtreleri:", filters);

      const notificationsSnapshot = await getDocs(notificationsQuery);
      // console.log("Snapshot Boyutu:", notificationsSnapshot.size);
      let fetchedNotifications = notificationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort notifications by createdAt date in descending order
      // fetchedNotifications.sort((a, b) => {
      //   const dateA = new Date(a.createdAt);
      //   const dateB = new Date(b.createdAt);
      //   return dateB - dateA;
      // });

      // console.log("Filtrelenmiş Bildirimler:", fetchedNotifications);

      setNotifications(fetchedNotifications);
    } catch (error) {
      console.error("Bildirimler getirilirken hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchEventTypes();
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [filter, startDate, endDate, eventType]);

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    setModalOpen(true);

    // Update status to read
    if (notification.status === "unread") {
      const notificationDocRef = doc(db, "adminLogs", notification.id);
      await updateDoc(notificationDocRef, {
        status: "read",
        readAt: new Date(),
      });
      fetchNotifications();
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      const notificationDocRef = doc(db, "adminLogs", notificationId);
      await updateDoc(notificationDocRef, {
        status: "deleted",
        deletedAt: new Date(),
      });
      fetchNotifications();
    } catch (error) {
      console.error("Bildirim silinirken hata oluştu:", error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedNotification(null);
  };
  // console.log("notifications:", notifications);
  return (
    <div className="container mt-4">
      <h2>Admin Bildirimleri</h2>

      {/* Date Filter Inputs */}
      <div className="mb-3">
        <label>Başlangıç Tarihi:</label>
        <input
          type="date"
          className="form-control mb-2"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <label>Bitiş Tarihi:</label>
        <input
          type="date"
          className="form-control"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>

      {/* Event Type Filter */}
      <div className="mb-3">
        <label>Konu Filtrele:</label>
        <select
          className="form-control"
          value={eventType}
          onChange={(e) => setEventType(e.target.value)}
        >
          {eventTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {/* Filter Buttons */}
      <div className="btn-group mb-3">
        <button
          className={`btn ${
            filter === "all" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setFilter("all")}
        >
          Tüm Mesajlar
        </button>
        <button
          className={`btn ${
            filter === "unread" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setFilter("unread")}
        >
          Okunmayanlar
        </button>
        <button
          className={`btn ${
            filter === "read" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setFilter("read")}
        >
          Okunmuşlar
        </button>
        <button
          className={`btn ${
            filter === "deleted" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => setFilter("deleted")}
        >
          Silinen Mesajlar
        </button>
      </div>

      {/* Notifications List */}
      <ul className="list-group">
        {notifications.map((notification) => (
          <li
            key={notification.id}
            className={`list-group-item d-flex justify-content-between align-items-center ${
              notification.status === "unread" ? "list-group-item-warning" : ""
            }`}
            onClick={() => handleNotificationClick(notification)}
          >
            <div>
              <strong>{notification.eventType}</strong> -{" "}
              {notification.eventDetails}
              <div className="text-muted" style={{ fontSize: "0.85em" }}>
                {new Date(notification.createdAt.seconds * 1000).toLocaleString(
                  "tr-TR"
                )}
              </div>
            </div>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteNotification(notification.id);
              }}
            >
              <i className="bi bi-trash"></i> Sil
            </button>
          </li>
        ))}
      </ul>

      {/* Notification Modal */}
      {modalOpen && selectedNotification && (
        <ShowModal title="Bildirim Detayı" onClose={closeModal}>
          <div>
            <p>
              <strong>Konusu:</strong> {selectedNotification.eventType}
            </p>
            <p>
              <strong>Mesaj:</strong> {selectedNotification.eventDetails}
            </p>
            <p>
              <strong>Tarih:</strong>{" "}
              {new Date(
                selectedNotification.createdAt.seconds * 1000
              ).toLocaleString("tr-TR")}
            </p>
            {selectedNotification.readAt && (
              <p>
                <strong>Okunma Tarihi:</strong>{" "}
                {new Date(
                  selectedNotification.readAt.seconds * 1000
                ).toLocaleString("tr-TR")}
              </p>
            )}
            {selectedNotification.deletedAt && (
              <p>
                <strong>Silinme Tarihi:</strong>{" "}
                {new Date(
                  selectedNotification.deletedAt.seconds * 1000
                ).toLocaleString("tr-TR")}
              </p>
            )}
          </div>
        </ShowModal>
      )}
    </div>
  );
}

export default AdminNotifications;
