import React, { useState } from "react";

import { Link } from "react-router-dom";

function NotificationCard() {
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
      <div className="card h-100 d-flex flex-column">
        <div className="card-body">
          <h5 className="card-header">Bildirim Yönetimi</h5>
          <p className="card-text">Bildirimlere buradan ulaşabilirsiniz.</p>
          <Link to="/notifications" className="btn btn-primary">
            Bildirimlere Git
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotificationCard;
