import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

function DeleteLeague({ league, onClose, onDeleteSuccess }) {
  const handleDelete = async () => {
    try {
      const leagueRef = doc(db, "leagues", league.id);
      await deleteDoc(leagueRef);

      // Silme işlemi başarılı olursa onDeleteSuccess() fonksiyonunu çağır
      onDeleteSuccess();
      onClose();
    } catch (error) {
      console.error("Lig silinemedi:", error);
    }
  };

  return (
    <div>
      <p>{league.name} adlı ligi silmek istediğinizden emin misiniz?</p>
      <button
        className="btn btn-danger mt-4"
        onClick={handleDelete}
        style={{ padding: "10px" }}
      >
        <i className="bi bi-trash" style={{ padding: "10px" }}>
          {" "}
          Sil{" "}
        </i>{" "}
      </button>
      <button
        className="btn btn-secondary mt-4"
        onClick={onClose}
        style={{ padding: "10px" }}
      >
        <i className="bi bi-x-square-fill" style={{ padding: "10px" }}>
          İptal
        </i>
      </button>
    </div>
  );
}

export default DeleteLeague;
