import React from "react";
import MatchList from "./MatchList";
import AddMatch from "./AddMatch";

function MatchManagement() {
  return (
    <div>
      <AddMatch />
      <MatchList />
      MatchManagement
    </div>
  );
}

export default MatchManagement;
