import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import Home from "./pages/Home";
import GuestHome from "./pages/GuestHome";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Profile from "./pages/Profile";
import Footer from "./component/Footer";
import Register from "./pages/Register";
import UserDashboard from "./pages/UserDashboard";
import Header from "./component/Header";
import AdminDashboard from "./pages/AdminDashboard";
import RoleManagement from "./component/RoleManagement";
import SettingsManagement from "./component/SettingsManagement";
import LeagueSettings from "./pages/LeagueSettings";
import RequireAdmin from "./component/RequireAdmin";
import AddLeague from "./component/AddLeague";
import UserManagement from "./component/UserManagement";
import MatchManagement from "./component/MatchManagement";
import AnnouncementManagement from "./pages/AnnouncementManagement";
import MatchMainOffer from "./pages/MatchOfferMain";
import MatchOfferReviewCarousel from "./pages/MatchOfferReviewCarousel";
import Notifications from "./pages/Notifications";
import LeagueList from "./pages/LeagueList";
import AdminNotifications from "./pages/AdminNotifications";
import AdminEmailSender from "./pages/AdminEmailSender";

const App = () => {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null); // Kullanıcı bilgileri için state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);

      setLoading(false);
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          // console.log(data);
          setUserInfo({
            firstName: data.firstName,
            lastName: data.lastName,
            rol: data.role,
            id: data.createdBy,
          });
        }
      } else {
        setUserInfo(null);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <Router>
      <div className="app">
        {user && <Header userInfo={userInfo} />}
        {/*" Header'a userInfo gönder"*/}
        {/* Kullanıcı giriş yaptıysa Header göster */}
        <Routes>
          {user ? (
            <>
              <Route path="/home" element={<Home />} />
              <Route
                path="/admin"
                element={
                  <RequireAdmin userInfo={userInfo}>
                    <AdminDashboard userInfo={userInfo} />
                  </RequireAdmin>
                }
              />
              <Route
                path="/match"
                element={<MatchMainOffer userInfo={userInfo} />}
              />
              <Route path="/leagues" element={<LeagueList />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route
                path="/rm"
                element={
                  <RequireAdmin userInfo={userInfo}>
                    <RoleManagement userInfo={userInfo} />
                  </RequireAdmin>
                }
              />
              <Route
                path="/match_rew"
                element={<MatchOfferReviewCarousel userInfo={userInfo} />}
              />
              <Route
                path="/userm"
                element={<UserManagement userInfo={userInfo} />}
              />
              <Route
                path="/adm-notifications"
                element={
                  <RequireAdmin userInfo={userInfo}>
                    <AdminNotifications />
                  </RequireAdmin>
                }
              />
              <Route
                path="/adm-mail"
                element={
                  <RequireAdmin userInfo={userInfo}>
                    <AdminEmailSender />
                  </RequireAdmin>
                }
              />

              <Route
                path="/announcement-management"
                element={
                  <RequireAdmin userInfo={userInfo}>
                    <AnnouncementManagement userInfo={userInfo} />
                  </RequireAdmin>
                }
              />
              <Route
                path="/macm"
                element={<MatchManagement userInfo={userInfo} />}
              />
              <Route
                path="/settings"
                element={
                  <RequireAdmin userInfo={userInfo}>
                    <SettingsManagement userInfo={userInfo} />
                  </RequireAdmin>
                }
              />
              <Route
                path="/addleague"
                element={
                  userInfo ? (
                    <RequireAdmin userInfo={userInfo}>
                      <AddLeague userInfo={userInfo} />
                    </RequireAdmin>
                  ) : (
                    <div>Loading user information...</div>
                  )
                }
              />

              <Route
                path="/league-settings"
                element={
                  userInfo ? (
                    <RequireAdmin userInfo={userInfo}>
                      <LeagueSettings userInfo={userInfo} />
                    </RequireAdmin>
                  ) : (
                    <div>Loading user information...</div>
                  )
                }
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="*" element={<Navigate to="/home" replace />} />
            </>
          ) : (
            <>
              <Route path="/guesthome" element={<GuestHome />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/guesthome" replace />} />
            </>
          )}
        </Routes>
        {/* {user && <Footer />} Only shows Footer if user is authenticated */}
      </div>
    </Router>
  );
};

export default App;
