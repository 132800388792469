import React, { useState } from "react";
import { auth } from "../firebase"; // Ensure this path is correct
import { sendPasswordResetEmail } from "firebase/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          // console.log("first");
          setMessage("Epostanızı kontrol ediniz.");
          // console.log(e);
        })
        .catch((err) => {
          if (err.code === "auth/invalid-email") {
            setError("Lütfen geçerli bir eposta adresi giriniz.");
          } else if (err.code === "auth/user-not-found") {
            setError(
              "Bu eposta hesabı kayıtlı ise, şifre sıfırlama linki eposta adresinize gönderilmiştir."
            );
          } else {
            setError("Bir hata oluştu, lütfen daha sonra deneyiniz.");
          }
          // console.log(err.code);
        });
    } catch (err) {
      // Handle Firebase errors
      console.log(err);
      if (err.code === "auth/invalid-email") {
        setError("Please enter a valid email address.");
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="container">
      <h2>Şifremi Unuttum</h2>
      <form onSubmit={handleForgotPassword}>
        <div className="form-group">
          <input
            type="email"
            id="email"
            placeholder="E-posta adresiniz..."
            className="form-control mt-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          Şifre Sıfırlama Postası Gönder
        </button>
      </form>
      {message && <p className="text-success mt-3">{message}</p>}
      {error && <p className="text-danger mt-3">{error}</p>}
      <p className="mt-3">
        <a href="/login">Giriş Yapın</a>
      </p>
    </div>
  );
};

export default ForgotPassword;
