import RequireAdmin from "../component/RequireAdmin";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Tabs, Tab } from "react-bootstrap";
import { cityList } from "../cityList";

function EditLeague({ userInfo, leagueId, onSuccess }) {
  const initialLeagueState = {
    name: "",
    startDate: "",
    endDate: "",
    rules: "",
    isActive: "true",
    createdById: userInfo?.id || "",
    modifiedById: "",
    createdAt: "",
    updatedAt: "",
    setCount: "",
    pointType: "",
    gameFormat: "",
    setTieBreakScore: 7,
    superTieBreak: 10,
    maxOffers: 3,
    maxDaysToRespond: 2,
    maxOffersReceive: 2,
    maxDaysToPlay: 5,
    maxDaysToRePlay: 30,
    city: 21,
  };
  // console.log(leagueId);

  const [league, setLeague] = useState(initialLeagueState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeague = async () => {
      if (!leagueId) {
        setError("Geçersiz lig ID.");
        setLoading(false);
        return;
      }

      try {
        const leagueRef = doc(db, "leagues", leagueId);
        const leagueDoc = await getDoc(leagueRef);

        if (leagueDoc.exists()) {
          setLeague(leagueDoc.data());
        } else {
          setError("Lig verisi bulunamadı.");
        }
      } catch (error) {
        console.error("Error fetching league:", error);
        setError("Lig verisi alınırken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeague();
  }, [leagueId]);

  const handleUpdateLeague = async (e) => {
    e.preventDefault();

    if (!userInfo?.id) {
      console.error("User ID is not available");
      return;
    }

    const updatedLeague = {
      ...league,
      modifiedById: userInfo.id,
      updatedAt: new Date().toISOString(),
      //isActive: league.isActive === "true",
    };

    try {
      const leagueRef = doc(db, "leagues", leagueId);
      await updateDoc(leagueRef, updatedLeague);
      onSuccess();
    } catch (error) {
      console.error("Error updating league:", error);
      setError("Lig güncellenirken bir hata oluştu.");
    }
  };

  const handleInputChange = (field, value) => {
    setLeague((prevLeague) => ({
      ...prevLeague,
      [field]: value,
    }));
  };

  if (loading) return <div>Yükleniyor...</div>;

  return (
    <RequireAdmin userInfo={userInfo}>
      <div className="container mt-4">
        {error && <div className="alert alert-danger">{error}</div>}
        <form
          onSubmit={handleUpdateLeague}
          className="border p-3 rounded bg-dark"
        >
          <Tabs
            defaultActiveKey="general"
            id="edit-league-tabs"
            className="mb-3"
          >
            {/* Genel Ayarlar */}
            <Tab eventKey="general" title="Genel Ayarlar">
              <div className="mb-3">
                <label className="form-label">Lig Adı:</label>
                <input
                  type="text"
                  className="form-control"
                  value={league.name || ""}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Lig Şehir:</label>
                <select
                  value={league.city || ""}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  className="form-control"
                  required
                >
                  <option value="">Bir il seçin</option>
                  {cityList.map((city) => (
                    <option key={city.code} value={city.code}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Başlangıç Tarihi:</label>
                <input
                  type="date"
                  className="form-control"
                  value={league.startDate || ""}
                  onChange={(e) =>
                    handleInputChange("startDate", e.target.value)
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Bitiş Tarihi:</label>
                <input
                  type="date"
                  className="form-control"
                  value={league.endDate || ""}
                  onChange={(e) => handleInputChange("endDate", e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Kurallar:</label>
                <textarea
                  className="form-control"
                  value={league.rules || ""}
                  onChange={(e) => handleInputChange("rules", e.target.value)}
                />
              </div>
            </Tab>

            {/* Kurallar */}
            <Tab eventKey="rules" title="Kurallar">
              <div className="mb-3">
                <label className="form-label">Set Sayısı:</label>
                <select
                  className="form-control"
                  value={league.setCount || ""}
                  onChange={(e) =>
                    handleInputChange("setCount", e.target.value)
                  }
                  required
                >
                  <option value="">Seçin</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Oyun Formatı Seçiniz:</label>
                <select
                  className="form-control"
                  value={league.gameFormat || ""}
                  onChange={(e) =>
                    handleInputChange("gameFormat", e.target.value)
                  }
                  required
                >
                  <option value="">Seçin</option>
                  <option value="4">4 Oyun</option>
                  <option value="6">6 Oyun</option>
                </select>
              </div>

              {/* Karar seti uygulaması*/}
              <div className="mb-3">
                <label className="form-label">Karar Seti Tipi:</label>
                <select
                  className="form-control"
                  value={league.setDecisionsFormat || ""}
                  onChange={(e) =>
                    handleInputChange("setDecisionsFormat", e.target.value)
                  }
                  required
                >
                  <option value="">Select</option>
                  <option value="Super">Süper Tie-Break</option>
                  <option value="Set">Set</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Puan Beraberliğinde:</label>
                <select
                  className="form-control"
                  value={league.pointType || ""}
                  onChange={(e) =>
                    handleInputChange("pointType", e.target.value)
                  }
                  required
                >
                  <option value="">Seçin</option>
                  <option value="advantage">Avantaj Oynansın</option>
                  <option value="deciding">Karar Sayısı Oynansın</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Set Tie-Break Sayısı:</label>
                <input
                  type="number"
                  className="form-control"
                  value={league.setTieBreakScore || ""}
                  onChange={(e) =>
                    handleInputChange("setTieBreakScore", e.target.value)
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Süper Tie-Break Sayısı:</label>
                <input
                  type="number"
                  className="form-control"
                  value={league.superTieBreak || ""}
                  onChange={(e) =>
                    handleInputChange("superTieBreak", e.target.value)
                  }
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Lig Cinsiyeti:</label>
                <select
                  className="form-control"
                  value={league.gender || ""}
                  onChange={(e) => handleInputChange("gender", e.target.value)}
                  required
                >
                  <option value="">Seçin</option>
                  <option value="male">Erkek</option>
                  <option value="female">Kadın</option>
                </select>
              </div>
            </Tab>

            {/* Maç Teklifi Ayarları */}
            <Tab eventKey="offerSettings" title="Maç Teklifi Ayarları">
              <div className="mb-3">
                <label className="form-label">
                  Max Kaç kişiye teklif yapılabilir:
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={league.maxOffers || ""}
                  onChange={(e) =>
                    handleInputChange("maxOffers", e.target.value)
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Max Cevap Süresi:</label>
                <input
                  type="number"
                  className="form-control"
                  value={league.maxDaysToRespond || ""}
                  onChange={(e) =>
                    handleInputChange("maxDaysToRespond", e.target.value)
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Max Teklif Alınabilir:</label>
                <input
                  type="number"
                  className="form-control"
                  value={league.maxOffersReceive || ""}
                  onChange={(e) =>
                    handleInputChange("maxOffersReceive", e.target.value)
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Max Kaç Günde Oynanmalı:</label>
                <input
                  type="number"
                  className="form-control"
                  value={league.maxDaysToPlay || ""}
                  onChange={(e) =>
                    handleInputChange("maxDaysToPlay", e.target.value)
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Max Kaç Günde Tekrar Oynanmalı:
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={league.maxDaysToRePlay || ""}
                  onChange={(e) =>
                    handleInputChange("maxDaysToRePlay", e.target.value)
                  }
                  required
                />
              </div>
            </Tab>
          </Tabs>

          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={league.isActive === true}
              onChange={(e) =>
                handleInputChange("isActive", e.target.checked ? true : false)
              }
            />
            <label className="form-check-label">Lig Aktif</label>
          </div>

          <div className="text-center mt-3">
            <button type="submit" className="btn btn-primary">
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </RequireAdmin>
  );
}

export default EditLeague;
