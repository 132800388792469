import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "bootstrap/dist/css/bootstrap.min.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Set user state in context

      // Başarılı giriş sonrası yönlendirme
      navigate("/home"); // Dashboard sayfasına yönlendirin
    } catch (error) {
      console.error("Giriş hatası:", error.message);
      // Burada hata mesajını gösterebilirsiniz
    }
  };

  return (
    <div className="container-fluid">
      <h2 className="my-4">Giriş Yap</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Şifre
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Giriş Yap
        </button>
      </form>
      <p className="mt-3">
        Hesabınız yok mu? <a href="/register">Üye Olun</a>
      </p>
      <p>
        Şifrenizi mi unuttunuz? <a href="/forgotpassword">Şifreyi Sıfırlayın</a>
      </p>
      <p>
        <a href="/guesthome">Misafir Girişi</a>
      </p>
    </div>
  );
};

export default Login;
