import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import path from "path";

// import { db } from "../firebase";
// import { sendEmail } from "../utils/sendEmail";

export async function prepareReminders() {
  try {
    // Dinamik olarak modülleri yükle
    const firebasePath = path.join(process.cwd(), "src", "firebase.js");
    // console.log("firebase path:", firebasePath); // path hatası giderildi : 2.12.2024
    const { db } = await import(firebasePath);

    const sendEmailPath = path.join(
      process.cwd(),
      "src",
      "utils",
      "sendEmail.js"
    );
    // console.log("sendEmail path : ", sendEmailPath);
    const { sendEmail } = await import(sendEmailPath);

    //Eposta gönderimi uygun saat kontrolü...
    const now = new Date();
    const formatter = new Intl.DateTimeFormat("tr-TR", {
      hour: "numeric",
      hourCycle: "h23",
      timeZone: "Europe/Istanbul",
    });
    const currentHour = parseInt(formatter.format(now), 10);

    if (currentHour < 8 || currentHour >= 23) {
      console.log("Mail gönderimi için uygun saat değil. İşlem durduruldu.");

      return { pendingCount: 0, expiredCount: 0, halfTimeRemindersCount: 0 };
    }
    // 1. Tüm "pending" teklifler için sorgu
    const pendingOffersSnapshot = await getDocs(
      query(
        collection(db, "matchOffers"),
        where("status", "==", "pending") // Tüm pending teklifler
      )
    );

    const expiredOffers = [];
    const halfTimeReminders = [];
    const pendingOffers = [];

    // 2. Lig bilgilerini alma
    const leaguesSnapshot = await getDocs(collection(db, "leagues"));
    const leagues = leaguesSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data();
      return acc;
    }, {});

    // 3. Tekliflerin durumu kontrol etme
    for (const offerDoc of pendingOffersSnapshot.docs) {
      const offer = offerDoc.data();
      const createdAt = offer.createdAt.toDate();
      const leagueId = offer.leagueId;
      const league = leagues[leagueId];
      const maxDaysToRespond = league?.maxDaysToRespond || 2;

      if (!league) {
        console.warn(`Lig bilgisi bulunamadı. League ID: ${leagueId}`);
        continue;
      }

      const maxRespondDate = new Date(createdAt);
      maxRespondDate.setDate(maxRespondDate.getDate() + maxDaysToRespond);

      const now = new Date();
      // Süresi dolmuş teklifler
      if (now >= maxRespondDate) {
        expiredOffers.push({ ...offer, id: offerDoc.id });
      } else {
        // Yarı süre kontrolü
        const halfTime = new Date(createdAt);
        halfTime.setDate(halfTime.getDate() + Math.floor(maxDaysToRespond / 2));

        if (now >= halfTime && !offer.halfTimeReminderSent) {
          halfTimeReminders.push({ ...offer, id: offerDoc.id });
        }
      }

      pendingOffers.push({ ...offer, id: offerDoc.id });
    }

    // E-posta gönderimi ve durum güncellemeleri
    for (const offer of expiredOffers) {
      const offererDoc = await getDoc(doc(db, "users", offer.offererId));
      const opponentDoc = await getDoc(doc(db, "users", offer.opponentId));
      const offererName = offererDoc.exists()
        ? offererDoc.data().firstName + " " + offererDoc.data().lastName
        : null;
      const opponentName = opponentDoc.exists()
        ? opponentDoc.data().firstName + " " + opponentDoc.data().lastName
        : null;
      const offererEmail = offererDoc.exists() ? offererDoc.data().email : null;
      const opponentEmail = opponentDoc.exists()
        ? opponentDoc.data().email
        : null;

      if (!offererEmail || !opponentEmail) {
        console.error(
          `E-posta adresleri alınamadı. Offerer ID: ${offer.offererId}, Opponent ID: ${offer.opponentId}`
        );
        continue; // Eğer e-posta eksikse bu teklifi atla
      }

      await sendEmail({
        to: offererEmail,
        subject: "Maç Teklifinizin Süresi Doldu",
        text: `Merhaba ${offererName}, maç teklifinde bulunduğunuz ${opponentName} teklifinize süresi içinde cevap vermediği için maçı siz kazanmış sayılacaksınız. 
        tenisportali.com`,
      });

      await sendEmail({
        to: opponentEmail,
        subject: "Maç Teklifinizin Süresi Doldu",
        text: `Merhaba ${opponentName}, maç teklifin bulunan ${offererName} karşısında süresi içinde cevap vermediğiniz için yenik sayıldınız.
        tenisportali.com`,
      });

      await updateDoc(doc(db, "matchOffers", offer.id), { status: "expired" });
      await addDoc(collection(db, "adminLogs"), {
        createdAt: new Date(),
        eventType: "EmailNotification_matchOffers_Expired",
        eventDetails: `Maç teklifi ile ilgili e-posta gönderildi. Offer ID: ${offer.id} - name : ${offererName}, Opponent ID: ${offer.opponentId} opponent name : ${opponentName}`,
        status: "unread",
      });
    }

    for (const offer of halfTimeReminders) {
      const opponentDoc = await getDoc(doc(db, "users", offer.opponentId));
      const offererDoc = await getDoc(doc(db, "users", offer.offererId));
      const opponentEmail = opponentDoc.exists()
        ? opponentDoc.data().email
        : null;
      const offererName = offererDoc.exists()
        ? offererDoc.data().firstName + " " + offererDoc.data().lastName
        : null;
      const opponentName = opponentDoc.exists()
        ? opponentDoc.data().firstName + " " + opponentDoc.data().lastName
        : null;

      if (!opponentEmail) {
        console.error(
          `Hatırlatma için e-posta adresi bulunamadı: Opponent ID ${offer.opponentId}`
        );
        continue;
      }
      // Sonlanma tarihini hesapla
      const createdAt = offer.createdAt.toDate();
      const maxRespondDate = new Date(createdAt);
      const maxDaysToRespond = leagues[offer.leagueId]?.maxDaysToRespond || 2;
      maxRespondDate.setDate(maxRespondDate.getDate() + maxDaysToRespond);

      // Tarihi formatla (ör. "03.12.2024 saat 18:30")
      const formatter = new Intl.DateTimeFormat("tr-TR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Istanbul",
      });
      const formattedMaxRespondDate = formatter.format(maxRespondDate);

      //HAtırlatma epostası gönder
      await sendEmail({
        to: opponentEmail,
        subject: "Maç Teklifi Hatırlatma",
        text: `Merhaba ${opponentName}, ${offererName} tarafından yapılan maç teklifinizi unutmayın. Süreniz ${formattedMaxRespondDate} tarihinde sona erecektir. Cevap verilmediği takdirde yenik sayılacak olup, lig sıralamasında 1 sıra gerileyeceksiniz. Lütfen teklifi zamanında cevaplayınız. 
        Bilginize! 
        tenisportali.com`,
      });

      await updateDoc(doc(db, "matchOffers", offer.id), {
        halfTimeReminderSent: true,
      });
      await addDoc(collection(db, "adminLogs"), {
        createdAt: new Date(),
        eventType: "EmailNotification_matchOffers_Reminder",
        eventDetails: `Maç teklifi ile ilgili e-posta gönderildi. Offer ID: ${offer.id} name :${offererName} , Opponent ID: ${offer.opponentId} - opponent name :${opponentName} son tarih :${formattedMaxRespondDate} `,
        status: "unread",
      });
    }

    return {
      pendingCount: pendingOffers.length,
      expiredCount: expiredOffers.length,
      halfTimeRemindersCount: halfTimeReminders.length,
      details: {
        pendingOffers,
        expiredOffers,
        halfTimeReminders,
      },
    };
  } catch (error) {
    console.error("Hatırlatma kontrolü sırasında hata oluştu:", error.message);
    throw error;
  }
}
