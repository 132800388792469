import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Firebase yapılandırmanızı ekleyin
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
} from "firebase/firestore";

const AnnouncementManagement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);

  const announcementsRef = collection(db, "announcements");

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const snapshot = await getDocs(announcementsRef);
      setAnnouncements(
        snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchAnnouncements();
  }, []);

  const handleSaveAnnouncement = async (e) => {
    e.preventDefault();
    const { title, content, endDate } = e.target.elements;

    if (currentAnnouncement) {
      await updateDoc(doc(db, "announcements", currentAnnouncement.id), {
        title: title.value,
        content: content.value,
        endDate: endDate.value,
      });
    } else {
      await addDoc(announcementsRef, {
        title: title.value,
        content: content.value,
        date: new Date(),
        endDate: endDate.value,
      });
    }
    setShowModal(false);
    setCurrentAnnouncement(null);
  };

  const handleEditAnnouncement = (announcement) => {
    setCurrentAnnouncement(announcement);
    setShowModal(true);
  };

  const handleDeleteAnnouncement = async (id) => {
    await deleteDoc(doc(db, "announcements", id));
  };

  return (
    <div className="container mt-4">
      <h2>Duyuru Yönetimi</h2>
      <button className="btn btn-primary" onClick={() => setShowModal(true)}>
        Yeni Duyuru Ekle
      </button>

      <ul className="list-group mt-3">
        {announcements.map((announcement) => (
          <li
            key={announcement.id}
            className="list-group-item d-flex justify-content-between"
          >
            <span>{announcement.title}</span>
            <div>
              <button
                className="btn btn-warning btn-sm me-2"
                onClick={() => handleEditAnnouncement(announcement)}
              >
                Düzenle
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => handleDeleteAnnouncement(announcement.id)}
              >
                Sil
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Modal */}
      {showModal && (
        <div className="modal show fade d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {currentAnnouncement ? "Duyuruyu Düzenle" : "Yeni Duyuru"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <form onSubmit={handleSaveAnnouncement}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Başlık</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      defaultValue={currentAnnouncement?.title || ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">İçerik</label>
                    <textarea
                      name="content"
                      className="form-control"
                      rows="3"
                      defaultValue={currentAnnouncement?.content || ""}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Bitiş Tarihi</label>
                    <input
                      type="date"
                      name="endDate"
                      className="form-control"
                      defaultValue={currentAnnouncement?.endDate || ""}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    İptal
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Kaydet
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementManagement;
