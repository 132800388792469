// AddLeague.jsx
import RequireAdmin from "../component/RequireAdmin";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { Tabs, Tab } from "react-bootstrap";
import { cityList } from "../cityList";

function AddLeague({ userInfo, onSuccess }) {
  const initialLeagueState = {
    name: "",
    startDate: "",
    endDate: "",
    rules: "",
    isActive: "true", // Set to "true" to allow string comparison in the form
    createdById: userInfo?.id || "",
    modifiedById: "",
    createdAt: new Date().toISOString(),
    updatedAt: "",
  };

  const [newLeague, setNewLeague] = useState(initialLeagueState);
  const [setCount, setSetCount] = useState("");
  const [pointType, setPointType] = useState("");
  const [gameFormat, setGameFormat] = useState("");
  const [gender, setGender] = useState("");
  const [setDecisionsFormat, setsetDecisionsFormat] = useState("");
  const [setTieBreakScore, setSetTieBreakScore] = useState(7);
  const [superTieBreak, setSuperTieBreak] = useState(10);
  const [maxOffers, setMaxOffers] = useState(3);
  const [maxDaysToRespond, setMaxDaysToRespond] = useState(2);
  const [maxOffersReceive, setMaxOffersReceive] = useState(2);
  const [maxDaysToPlay, setMaxDaysToPlay] = useState(5);
  const [maxDaysToRePlay, setMaxDaysToRePlay] = useState(30);
  const [city, setCity] = useState(21);

  const handleAddLeague = async (e) => {
    e.preventDefault();

    if (!userInfo?.id) {
      console.error("User ID is not available");
      return;
    }

    const leagueData = {
      ...newLeague,
      setCount,
      pointType,
      gameFormat,
      setDecisionsFormat,
      maxOffers: parseInt(maxOffers, 10),
      maxDaysToRespond: parseInt(maxDaysToRespond, 10),
      maxOffersReceive: parseInt(maxOffersReceive, 10),
      maxDaysToPlay: parseInt(maxDaysToPlay, 10),
      maxDaysToRePlay: parseInt(maxDaysToRePlay, 10),
      setTieBreakScore: parseInt(setTieBreakScore, 10),
      superTieBreak: parseInt(superTieBreak, 10),
      createdById: userInfo.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      city,
      isActive: newLeague.isActive === true,
      gender,
    };

    try {
      await addDoc(collection(db, "leagues"), leagueData);
      // Reset form after successful submission
      setNewLeague(initialLeagueState);
      setSetCount("");
      setPointType("");
      setGameFormat("");
      setsetDecisionsFormat("");
      setSetTieBreakScore(7);
      setSuperTieBreak(10);
      setMaxOffers(3);
      setMaxDaysToRespond(2);
      setMaxOffersReceive(2);
      setMaxDaysToPlay(5);
      setCity("");
      setMaxDaysToRePlay(30);
      onSuccess();
    } catch (error) {
      console.error("Error adding league:", error);
    }
  };

  return (
    <RequireAdmin userInfo={userInfo}>
      <div className="container mt-4">
        <form onSubmit={handleAddLeague} className="border p-3 rounded bg-dark">
          <Tabs
            defaultActiveKey="general"
            id="league-settings-tabs"
            className="mb-3"
          >
            {/* General Settings Tab */}
            <Tab eventKey="general" title="Genel Ayarlar">
              <div className="mb-3">
                <label className="form-label">Lig Adı:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Lig Adı"
                  value={newLeague.name}
                  onChange={(e) =>
                    setNewLeague({ ...newLeague, name: e.target.value })
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Lig Şehir:</label>
                <select
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="form-control"
                  required
                >
                  <option value="">Bir il seçin</option>
                  {cityList.map((city) => (
                    <option key={city.code} value={city.code}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Başlangıç Tarihi:</label>
                <input
                  type="date"
                  className="form-control"
                  value={newLeague.startDate}
                  onChange={(e) =>
                    setNewLeague({ ...newLeague, startDate: e.target.value })
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Bitiş Tarihi:</label>
                <input
                  type="date"
                  className="form-control"
                  value={newLeague.endDate}
                  onChange={(e) =>
                    setNewLeague({ ...newLeague, endDate: e.target.value })
                  }
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Kurallar:</label>
                <textarea
                  className="form-control"
                  placeholder="Açıklamalar"
                  value={newLeague.rules}
                  onChange={(e) =>
                    setNewLeague({ ...newLeague, rules: e.target.value })
                  }
                />
              </div>
            </Tab>

            {/* Rules Tab */}
            <Tab eventKey="rules" title="Kurallar">
              {/* Set Count */}
              <div className="mb-3">
                <label className="form-label">Set Sayısı:</label>
                <select
                  className="form-control"
                  value={setCount}
                  onChange={(e) => setSetCount(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                </select>
              </div>
              {/* Game Format */}
              <div className="mb-3">
                <label className="form-label">Oyun Formatı Seçiniz:</label>
                <select
                  className="form-control"
                  value={gameFormat}
                  onChange={(e) => setGameFormat(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="4">4 Oyun</option>
                  <option value="6">6 Oyun</option>
                </select>
              </div>

              {/* Karar seti uygulaması*/}
              <div className="mb-3">
                <label className="form-label">Karar Seti Tipi:</label>
                <select
                  className="form-control"
                  value={setDecisionsFormat}
                  onChange={(e) => setsetDecisionsFormat(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Super">Süper Tie-Break</option>
                  <option value="Set">Set</option>
                </select>
              </div>
              {/* Point Type */}
              <div className="mb-3">
                <label className="form-label">Puan Beraberliğinde:</label>
                <select
                  className="form-control"
                  value={pointType}
                  onChange={(e) => setPointType(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="advantage">Avantaj Oynansın</option>
                  <option value="deciding">Karar Sayısı Oynansın</option>
                </select>
              </div>
              {/* Set Tie-Break Score */}
              <div className="mb-3">
                <label className="form-label">
                  Set Tie-Break Sayısını Giriniz:
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={setTieBreakScore}
                  onChange={(e) => setSetTieBreakScore(e.target.value)}
                  required
                />
              </div>
              {/* Super Tie-Break Score */}
              <div className="mb-3">
                <label className="form-label">Süper Tie-Break Sayısı:</label>
                <input
                  type="number"
                  className="form-control"
                  value={superTieBreak}
                  onChange={(e) => setSuperTieBreak(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Lig Cinsiyeti:</label>
                <select
                  className="form-control"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value="">Seçin</option>
                  <option value="male">Erkek</option>
                  <option value="female">Kadın</option>
                </select>
              </div>
            </Tab>

            {/* Offer Settings Tab */}
            <Tab eventKey="offerSettings" title="Maç Teklifi Ayarları">
              {/* Max Offers */}
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Max Kaç kişiye teklif yapılabilir:
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={maxOffers}
                  onChange={(e) => setMaxOffers(e.target.value)}
                  required
                />
              </div>
              {/* Response Time */}
              <div className="mb-3">
                <label className="form-label">
                  Maç teklifine cevap verme süresi (Gün){" "}
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={maxDaysToRespond}
                  onChange={(e) => setMaxDaysToRespond(e.target.value)}
                  required
                />
              </div>
              {/* Max Offers Receive */}
              <div className="mb-3">
                <label className="form-label">
                  Max aynı anda kaç teklif alınabilir:
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={maxOffersReceive}
                  onChange={(e) => setMaxOffersReceive(e.target.value)}
                  required
                />
              </div>
              {/* Play Time */}
              <div className="mb-3">
                <label className="form-label">
                  Maç kaç gün içinde oynanmalı (Gün):
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={maxDaysToPlay}
                  onChange={(e) => setMaxDaysToPlay(e.target.value)}
                  required
                />
              </div>
              {/* Replay Time */}
              <div className="mb-3">
                <label className="form-label">
                  Yenildiği oyuncuya teklif için kaç gün beklenmeli
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={maxDaysToRePlay}
                  onChange={(e) => setMaxDaysToRePlay(e.target.value)}
                  required
                />
              </div>
            </Tab>
          </Tabs>

          <button type="submit" className="btn btn-primary mt-3">
            Lig Ekle
          </button>
        </form>
      </div>
    </RequireAdmin>
  );
}

export default AddLeague;
