const ExcuseCheck = ({ participants, userExcuse, onNextStep, onPrevStep }) => (
  <div>
    <div className="card text-bg-dark mb-3">
      <div className="card text-bg-secondary mb-3">
        <div className="card-header">Mazeret Kontrolü</div>
        <div className="card-body">
          <h5 className="card-title">Genel Açıklamalar</h5>
          <p className="card-text">
            Bu sayfada sizin ve ligdeki diğer oyuncuların mazeret durumunu
            görebilirsiniz.
          </p>
        </div>
      </div>
      {userExcuse ? (
        <div className="card text-bg-warning mb-3">
          <div className="card-header">Mazeretiniz Var!!</div>
          <div className="card-body">
            <h5 className="card-title">Teklif Yapılamaz...</h5>
            <p className="card-text">
              Şuan aktif bir mazeretiniz bulunmaktadır.
              {userExcuse.excuseType + " "} nedeniyle
              {" " + userExcuse.endDate + " "} tarihinde bitecek olan
              mazeretinizin süresini beklemeniz gerekmektedir.
            </p>
          </div>
        </div>
      ) : (
        <p>Şu anda herhangi mazeretiniz bulunmamaktadır.</p>
      )}
      <div className="card text-bg-warning mb-3">
        <div className="card-header">
          Ligdeki Diğer Oyuncuların Mazeret Durumu
        </div>
        <div className="card-body">
          <div className="card-text">
            <ul>
              {participants
                .filter(
                  (participant) =>
                    participant &&
                    participant.excuses.length > 0 &&
                    participant.userId !== userExcuse.userId
                )
                .map((participant) => (
                  <li key={participant.userId}>
                    <strong>
                      {participant.firstName} {participant.lastName}
                    </strong>
                    :
                    {participant.excuses.map((excuse, index) => {
                      const endDate = new Date(excuse.endDate);
                      const currentDate = new Date();
                      const daysRemaining = Math.ceil(
                        (endDate - currentDate) / (1000 * 60 * 60 * 24)
                      );

                      return (
                        <div key={index}>
                          {excuse.excuseType} nedeniyle mazereti
                          bulunmaktadır.Mazeretin: Bitiş tarihi:{" "}
                          {endDate.toLocaleDateString("tr-TR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                          , kalan gün:{" "}
                          {daysRemaining > 0 ? daysRemaining : "Süre doldu"}
                        </div>
                      );
                    })}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button className="btn btn-info me-3" onClick={onPrevStep}>
      {"<"} Geri
    </button>
    <button
      className="btn btn-info"
      onClick={onNextStep}
      disabled={userExcuse !== null}
    >
      İleri {">"}
    </button>
  </div>
);

export default ExcuseCheck;
