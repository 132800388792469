import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function RequireAdmin({ userInfo, children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkUserInfo = () => {
      if (!userInfo) {
        // console.warn("User info is not loaded yet.");
        setLoading(true);
        return;
      }

      setLoading(false);

      // Burada rol kontrolünü güncelle
      if (!userInfo.rol) {
        // console.warn("User info is missing or invalid.");
        setError("Kullanıcı bilgileri yüklenemedi.");
        navigate("/");
      } else {
        // Eğer kullanıcı admin veya superAdmin değilse yönlendir
        if (userInfo.rol !== "Admin" && userInfo.rol !== "SuperAdmin") {
          console.log("Yetkiniz yok.");
          navigate("/");
        }
      }
    };

    // Kullanıcı bilgilerini kontrol et
    checkUserInfo();
  }, [userInfo, navigate]);

  // Yükleme durumu kontrolü
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </div>
      </div>
    );

  // Hata durumu kontrolü
  if (error) {
    return <div>{error}</div>;
  }

  // Kullanıcı admin veya superAdmin ise bileşenleri göster
  return userInfo?.rol === "Admin" || userInfo?.rol === "SuperAdmin"
    ? children
    : null;
}

export default RequireAdmin;
