import React, { useEffect, useState } from "react";

const MatchOfferModal = ({
  showModal,
  closeModal,
  players,
  onOfferSelect,
  user_id,
}) => {
  const [userRank, setUserRank] = useState(null);

  useEffect(() => {
    // Kullanıcının sıralamasını bulmak ve ayarlamak
    const currentPlayer = players.find((player) => player.userId === user_id);
    if (currentPlayer) setUserRank(Number(currentPlayer.rank));
  }, [user_id, players]);

  return (
    <div
      className={`modal ${showModal ? "show" : ""}`}
      style={{ display: showModal ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Teklif Edilebilecek Oyuncular</h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Sıra No</th>
                  <th>Ad Soyad</th>
                  <th>Teklif</th>
                </tr>
              </thead>
              <tbody>
                {userRank !== null &&
                  players
                    .filter(
                      (player) =>
                        Number(player.rank) >= userRank - 5 &&
                        Number(player.rank) < userRank &&
                        player.userId !== user_id // Kullanıcıyı listeden çıkar
                    )
                    .map((player) => {
                      const isExcused = player.excuse !== "";

                      return (
                        <tr key={player.userId}>
                          <td>{player.rank}</td>
                          <td>
                            {player.firstName} {player.lastName}
                            {isExcused && (
                              <span className="text-warning ms-2">
                                (Mazeretli)
                              </span>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => onOfferSelect(player)}
                              disabled={isExcused} // Mazeretli ise butonu devre dışı bırak
                            >
                              Teklif Et
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                {userRank === 1 && (
                  <tr>
                    <td colSpan="3" className="text-center">
                      Tebrikler, siz zaten 1. sıradasınız. Lütfen teklifleri
                      bekleyiniz.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchOfferModal;
