import React from "react";
import { Link } from "react-router-dom";

const GuestHome = () => {
  return (
    <div className="container text-center">
      <h1>Hoşgeldin</h1>
      <p>Siteye tam erişim için kayıt olun veya giriş yapın.</p>
      <div>
        <Link to="/login" className="btn btn-primary m-2">
          Giriş
        </Link>
        <Link to="/register" className="btn btn-secondary m-2">
          Kayıt
        </Link>
      </div>
    </div>
  );
};

export default GuestHome;
