const LeagueRules = ({ activeLeague, onNextStep }) => (
  <div>
    <h4 className="header text-info">{activeLeague.name} Lig Kuralları</h4>
    <ul className="list-group">
      <li className="list-group-item list-group-item-warning">
        Maç toplam <strong> {activeLeague.setCount}</strong> set üzerinden
        oynanacaktır.
      </li>
      <li className="list-group-item list-group-item-warning">
        Set beraberliğinde:
        <strong>
          {activeLeague.setDecisionsFormat === "Super"
            ? "Süper Tie-break "
            : activeLeague.setDecisionsFormat === "Set"
            ? "Karar Seti"
            : "geçersiz format"}
        </strong>{" "}
        oynanacaktır.
      </li>
      <li className="list-group-item list-group-item-warning">
        Oyun beraberliğinde: <strong>{activeLeague.setTieBreakScore}</strong>{" "}
        puanlık Set Tie-break oynanacaktır.
      </li>
      {activeLeague.setDecisionsFormat === "Super" && (
        <li className="list-group-item list-group-item-warning">
          Süper Tie-break Sayısı: <strong>{activeLeague.superTieBreak}</strong>{" "}
          sayı olarak oynanacaktır.
        </li>
      )}
    </ul>
    <button className="btn btn-info mt-2" onClick={onNextStep}>
      Kuralları Kabul Et ve Devam Et
    </button>
  </div>
);

export default LeagueRules;
