import React from "react";
import { Link } from "react-router-dom";

const AdminDashboard = ({ userInfo }) => {
  return (
    <>
      <div className="container mt-4">
        <h2>Admin Dashboard</h2>
        <div className="row">
          {/* Role Management Card */}
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Role Management</h5>
                <p className="card-text">Manage user roles and permissions.</p>
                <Link to="/rm" className="btn btn-primary" state={{ userInfo }}>
                  Go to Role Management
                </Link>
              </div>
            </div>
          </div>

          {/* Settings Management Card */}
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Settings Management</h5>
                <p className="card-text">
                  Adjust general application settings.
                </p>
                <Link to="/settings" className="btn btn-primary">
                  Go to Settings
                </Link>
              </div>
            </div>
          </div>

          {/* Admin Notifications Card */}
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Admin Notifications</h5>
                <p className="card-text">Admin Notifications screen.</p>
                <Link to="/adm-notifications" className="btn btn-primary">
                  Go to Admin Notifications
                </Link>
              </div>
            </div>
          </div>

          {/* Lig Ayarları Linki */}
          <div className="col-md-4">
            <div className="card text-bg-secondary mb-3">
              <div className="card-header">Lig Ayarları</div>
              <div className="card-body">
                <p className="card-text">Adjust general league settings.</p>
                <Link
                  to="/league-settings"
                  state={{ userInfo }}
                  className="btn btn-info"
                >
                  Go to League Settings
                </Link>
              </div>
            </div>
          </div>

          {/* Kullanıcı İşlemleri Card */}
          <div className="col-md-4 mt-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Kullanıcı İşlemleri</h5>
                <p className="card-text">Add, update, or delete users.</p>
                <Link
                  to="/userm"
                  state={{ userInfo }}
                  className="btn btn-primary"
                >
                  Go to User Management
                </Link>
              </div>
            </div>
          </div>

          {/* Duyuru Yönetimi Kartı */}
          <div className="col-md-4 mt-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Duyuru Yönetimi</h5>
                <p className="card-text">
                  Duyuruları oluşturun, güncelleyin veya silin.
                </p>
                <Link to="/announcement-management" className="btn btn-primary">
                  Duyuru Yönetimine Git
                </Link>
              </div>
            </div>
          </div>

          {/* Maç İşlemleri Card */}
          <div className="col-md-4 mt-3">
            <div className="card text-center">
              <div className="card-body">
                <h5 className="card-title">Maç İşlemleri</h5>
                <p className="card-text">Add, update, or delete maches.</p>
                <Link
                  to="/macm"
                  state={{ userInfo }}
                  className="btn btn-primary"
                >
                  Go to Match Management
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
