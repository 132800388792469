import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { prepareReminders } from "../utils/checkAndPrepareReminders";

const AdminEmailSender = () => {
  const [emailSubject, setSubject] = useState("");
  const [recipientType, setRecipientType] = useState("specific");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [leagues, setLeagues] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedLeaguePlayers, setSelectedLeaguePlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState("");

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const leaguesSnapshot = await getDocs(
          query(collection(db, "leagues"), where("isActive", "==", true))
        );
        const leaguesData = leaguesSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setLeagues(leaguesData);
      } catch (error) {
        console.error("Ligler alınamadı:", error);
      }
    };

    fetchLeagues();
  }, []);

  useEffect(() => {
    const fetchLeaguePlayers = async () => {
      if (!selectedLeague) return;

      try {
        const playersSnapshot = await getDocs(
          query(
            collection(db, "leagueParticipants"),
            where("leagueId", "==", selectedLeague)
          )
        );
        const players = await Promise.all(
          playersSnapshot.docs.map(async (playerDoc) => {
            const playerData = playerDoc.data();
            const userDoc = await getDoc(doc(db, "users", playerData.userId));
            if (userDoc.exists()) {
              return {
                id: playerData.userId,
                email: userDoc.data().email,
                firstName: userDoc.data().firstName,
                lastName: userDoc.data().lastName,
              };
            }
            return null;
          })
        );
        setSelectedLeaguePlayers(players.filter((player) => player !== null));
      } catch (error) {
        console.error("Oyuncular alınamadı:", error);
      }
    };

    fetchLeaguePlayers();
  }, [selectedLeague]);

  const handleRunReminderCheck = async () => {
    try {
      const result1 = await prepareReminders();

      // Sonuç nesnesini kontrol et
      console.log("Raw Result from prepareReminders:", result1);

      // Değerleri güvenli bir şekilde al
      const pendingOffers = result1.pendingCount || 0; // Eğer undefined ise 0 alır
      const expiredOffers = result1.expiredCount || 0;
      const halfTimeReminders = result1.halfTimeRemindersCount || 0;

      console.log("pendingOffers:", pendingOffers);
      console.log("expiredOffers:", expiredOffers);
      console.log("halfTimeReminders:", halfTimeReminders);

      // Sayıları hesapla
      const pendingCount = pendingOffers;
      const expiredCount = expiredOffers;
      const halfTimeRemindersCount = halfTimeReminders;
      console.log("pendingOffers:", pendingOffers);

      alert(
        `Hatırlatma kontrolü başarıyla tamamlandı!\n\n` +
          `- Toplam Pending Teklifler: ${pendingCount}\n` +
          `- Süresi Dolan Teklifler: ${expiredCount}\n` +
          `- Yarı Süreyi Geçen ve Hatırlatılan Teklifler: ${halfTimeRemindersCount}`
      );

      console.log("Hatırlatma Kontrol Sonuçları:", {
        pendingCount,
        expiredCount,
        halfTimeRemindersCount,
        details: result1,
      });
    } catch (error) {
      alert("Hatırlatma kontrolü sırasında bir hata oluştu.");
      console.error("Hatırlatma kontrolü hatası:", error);
    }
  };

  const handleSendEmail = async () => {
    const emailData = {
      to:
        recipientType === "league"
          ? selectedPlayers
          : recipientType === "specific"
          ? [recipientEmail]
          : [], // Tüm kullanıcılar için başka bir yapı eklenebilir
      subject: emailSubject,
      text: message,
    };

    try {
      const response = await fetch("/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        setNotification("E-posta başarıyla gönderildi!");
      } else {
        setNotification("E-posta gönderimi başarısız oldu.");
      }
    } catch (error) {
      console.error("E-posta gönderiminde hata:", error);
      setNotification("E-posta gönderimi sırasında bir hata oluştu.");
    }
  };

  return (
    <div className="container mt-4">
      <h2>E-Posta Gönder</h2>

      <div className="mb-3">
        <label className="form-label">Konu</label>
        <input
          type="text"
          className="form-control"
          value={emailSubject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="E-posta konusu yazınız"
          required
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Alıcı Seçimi</label>
        <select
          className="form-select"
          value={recipientType}
          onChange={(e) => setRecipientType(e.target.value)}
        >
          <option value="specific">Belirli bir e-posta adresi</option>
          <option value="league">Bir ligdeki oyuncular</option>
        </select>
      </div>

      {recipientType === "specific" && (
        <div className="mb-3">
          <label className="form-label">Alıcı E-Posta Adresi</label>
          <input
            type="email"
            className="form-control"
            value={recipientEmail}
            onChange={(e) => setRecipientEmail(e.target.value)}
            placeholder="E-posta adresi giriniz"
          />
        </div>
      )}

      {recipientType === "league" && (
        <>
          <div className="mb-3">
            <label className="form-label">Lig Seçimi</label>
            <select
              className="form-select"
              value={selectedLeague}
              onChange={(e) => setSelectedLeague(e.target.value)}
            >
              <option value="">Bir lig seçiniz</option>
              {leagues.map((league) => (
                <option key={league.id} value={league.id}>
                  {league.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="select-all"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedPlayers(
                    checked
                      ? selectedLeaguePlayers.map((player) => player.email)
                      : []
                  );
                }}
                checked={
                  selectedPlayers.length === selectedLeaguePlayers.length &&
                  selectedLeaguePlayers.length > 0
                }
              />
              <label className="form-check-label" htmlFor="select-all">
                Tümünü Seç
              </label>
            </div>

            {selectedLeaguePlayers.map((player) => (
              <div key={player.id} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value={player.email}
                  checked={selectedPlayers.includes(player.email)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setSelectedPlayers((prev) =>
                      checked
                        ? [...prev, player.email]
                        : prev.filter((email) => email !== player.email)
                    );
                  }}
                />
                <label className="form-check-label">
                  {player.firstName} {player.lastName} ({player.email})
                </label>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="mb-3">
        <label className="form-label">Mesaj</label>
        <textarea
          className="form-control"
          rows="5"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="E-posta mesajınızı buraya yazın"
        />
      </div>

      <button
        className="btn btn-primary"
        onClick={handleSendEmail}
        disabled={!emailSubject || !message}
      >
        Gönder
      </button>

      <div className="alert alert-info">
        <button onClick={handleRunReminderCheck}>
          Hatırlatma Kontrolünü Çalıştır
        </button>
      </div>

      {notification && (
        <div className="alert alert-info mt-3">{notification}</div>
      )}
    </div>
  );
};

export default AdminEmailSender;
