export async function sendEmail({ to, subject, text }) {
  try {
    // Doğrudan fetch ile API'yi çağır
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ to, subject, text }),
    });

    // Yanıtı kontrol et
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Failed to send email: ${response.statusText}. ${errorText}`
      );
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error in sendEmail:", error.message);
    throw error;
  }
}
