import React, { useState, useEffect } from "react";
import UserDashboard from "./UserDashboard";
import Profile from "./Profile";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function Home() {
  // console.log(props.userE);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    // Kullanıcının mevcut profil bilgilerini al

    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          // Kullanıcının mevcut profil bilgilerini form alanlarına doldur
          const data = userDoc.data();
          setUserData({
            ...data,
            uid: user.uid,
          });
        }
      }
    };
    fetchUserProfile();
  }, []);

  return <div>{userData.uid ? <UserDashboard /> : <Profile />}</div>;
}

export default Home;
