import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  getDoc,
  addDoc,
} from "firebase/firestore";
import Carousel from "react-bootstrap/Carousel";
import LeagueRules from "../component/LeagueRules";
import ExcuseCheck from "../component/ExcuseCheck";
import OfferPlayerList from "../component/OfferPlayerList";
import SendMatchOffer from "../component/SendMatchOffer";
import { sendEmail } from "../utils/sendEmail_jsx";

function MatchMainOffer({ userInfo, leagueId }) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [userLeagues, setUserLeagues] = useState([]);
  const [activeLeague, setActiveLeague] = useState(null);
  const [pastLeagues, setPastLeagues] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [leagueRulesAccepted, setLeagueRulesAccepted] = useState(false);
  const [userExcuse, setUserExcuse] = useState(null);
  const [selectedOpponent, setSelectedOpponent] = useState(null);
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [loading, setLoading] = useState(true);

  const [leagueIsActive, setLeagueIsActive] = useState(false);
  const [leagueIsValid, setLeagueIsValid] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [userPendingOffers, setUserPendingOffers] = useState([]); // Oyuncunun yaptığı teklifler
  const [receivedOffers, setReceivedOffers] = useState([]); // Oyuncuya yapılan teklifler
  const [isSubmitting, setIsSubmitting] = useState(false); // mükerrer kayıt olmasın kontrolü

  useEffect(() => {
    // Kullanıcı bilgilerini al
    const fetchUserProfile = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserData({
              ...userDoc.data(),
              id: user.uid,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserProfile();
  }, []);

  useEffect(() => {
    // Kullanıcının kayıtlı olduğu tüm ligleri al
    const fetchUserLeagues = async () => {
      if (userData.id) {
        try {
          const leagueParticipantsSnapshot = await getDocs(
            query(
              collection(db, "leagueParticipants"),
              where("userId", "==", userData.id)
            )
          );
          const allLeagues = leagueParticipantsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const activeLeagues = [];
          const pastLeaguesList = [];

          for (const league of allLeagues) {
            const leagueDocRef = doc(db, "leagues", league.leagueId);
            const leagueDoc = await getDoc(leagueDocRef);
            if (leagueDoc.exists()) {
              const leagueData = leagueDoc.data();

              //bugünün tarihi
              const today = new Date();
              //ligin son tarihi
              const endDate = new Date(leagueData.endDate);

              // Lig aktif ve devam ediyor mu?
              if (leagueData.isActive && endDate > today) {
                activeLeagues.push({ ...leagueData, id: leagueDoc.id });
              } else {
                pastLeaguesList.push({ ...leagueData, id: leagueDoc.id });
              }
            }
          }

          setUserLeagues(allLeagues);
          setActiveLeague(activeLeagues.length > 0 ? activeLeagues[0] : null);
          setPastLeagues(pastLeaguesList);
        } catch (error) {
          console.error("Error fetching user leagues:", error);
        }
      }
    };
    fetchUserLeagues();
  }, [userData]);

  useEffect(() => {
    const fetchParticipantsWithExcuses = async () => {
      if (activeLeague) {
        try {
          const participantsSnapshot = await getDocs(
            query(
              collection(db, "leagueParticipants"),
              where("leagueId", "==", activeLeague.id)
            )
          );

          const userIds = participantsSnapshot.docs.map(
            (doc) => doc.data().userId
          );

          const excusesSnapshot = await getDocs(
            query(collection(db, "excuses"), where("userId", "in", userIds))
          );

          const currentDate = new Date();
          const validExcuses = excusesSnapshot.docs
            .map((excuseDoc) => excuseDoc.data())
            .filter((excuse) => {
              const endDate = excuse.endDate ? new Date(excuse.endDate) : null;
              return !endDate || endDate >= currentDate;
            });

          // Kullanıcının kendi mazeretini bulma
          const userExcuseData = validExcuses.find(
            (excuse) => excuse.userId === userData.id
          );
          setUserExcuse(userExcuseData || null);

          // Fetch active match offers for participants
          const matchOffersSnapshot = await getDocs(
            query(
              collection(db, "matchOffers"),
              where("opponentId", "in", userIds),
              where("status", "==", "pending")
            )
          );

          const pendingOffersCount = {};
          matchOffersSnapshot.docs.forEach((offerDoc) => {
            const { opponentId } = offerDoc.data();
            if (!pendingOffersCount[opponentId]) {
              pendingOffersCount[opponentId] = 0;
            }
            pendingOffersCount[opponentId]++;
          });

          // Fetch active match offers made by the user
          const userOffersSnapshot = await getDocs(
            query(
              collection(db, "matchOffers"),
              where("offererId", "==", userData.id),
              where("status", "==", "pending")
            )
          );

          setUserPendingOffers(
            userOffersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          );

          const leagueParticipants = await Promise.all(
            participantsSnapshot.docs.map(async (participantDoc) => {
              const participantData = participantDoc.data();
              const userDocRef = doc(db, "users", participantData.userId);
              const userDoc = await getDoc(userDocRef);

              if (userDoc.exists()) {
                const excusesForUser = validExcuses.filter(
                  (excuse) => excuse.userId === participantData.userId
                );

                return {
                  id: participantDoc.id,
                  userId: participantData.userId,
                  points: participantData.points || 0,
                  rank: participantData.rank,
                  firstName: userDoc.data().firstName || "Adı Yok",
                  lastName: userDoc.data().lastName || "Soyadı Yok",
                  email: userDoc.data().email || null,
                  excuses: excusesForUser,
                  pendingOffers:
                    pendingOffersCount[participantData.userId] || 0,
                };
              } else {
                return null;
              }
            })
          );

          setParticipants(leagueParticipants);
        } catch (err) {
          console.error("Error fetching participants with excuses:", err);
        }
      }
    };
    fetchParticipantsWithExcuses();
  }, [activeLeague]);
  useEffect(() => {
    const fetchOffers = async () => {
      if (activeLeague && userData.id) {
        try {
          // Kullanıcıya yapılmış teklifler
          const receivedOffersSnapshot = await getDocs(
            query(
              collection(db, "matchOffers"),
              where("opponentId", "==", userData.id),
              where("status", "==", "pending")
            )
          );
          const receivedOffersWithNames = await Promise.all(
            receivedOffersSnapshot.docs.map(async (offerDoc) => {
              const offerData = offerDoc.data();
              const offererDocRef = doc(db, "users", offerData.offererId);
              const offererDoc = await getDoc(offererDocRef);

              const offererName = offererDoc.exists()
                ? `${offererDoc.data().firstName} ${offererDoc.data().lastName}`
                : "Bilinmiyor";

              return {
                id: offerDoc.id,
                ...offerData,
                offererName,
              };
            })
          );

          setReceivedOffers(receivedOffersWithNames);

          // Kullanıcının yaptığı teklifler
          const userOffersSnapshot = await getDocs(
            query(
              collection(db, "matchOffers"),
              where("offererId", "==", userData.id),
              where("status", "==", "pending")
            )
          );
          setUserPendingOffers(
            userOffersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          );
        } catch (error) {
          console.error("Error fetching offers:", error);
        }
      }
    };
    console.log("use effect içindeyim. activestep:", activeStep);

    fetchOffers();
    setLoading(false);
  }, [activeLeague, userData, activeStep]); // activeStep, teklifler değiştiğinde yeniden yüklenmesi için bağımlılık.

  const sendMatchOffer = async (opponent) => {
    setSelectedOpponent(opponent);
    handleNextStep(); // Carousel'in 4. sayfasına geç
  };

  const handleSendOffer = async () => {
    if (isSubmitting) return; // Eğer işlem devam ediyorsa, tekrar çalıştırma.
    setIsSubmitting(true); // İşlem kilidi.
    if (offerAccepted && selectedOpponent) {
      if (!selectedOpponent.email) {
        alert(
          "Rakip oyuncunun e-posta adresi bulunamadı. Lütfen bir rakip seçiniz."
        );
        setIsSubmitting(false);
        return;
      }
      try {
        const timestamp = new Date();

        // Maç teklifini Firestore'a ekle
        const offerRef = await addDoc(collection(db, "matchOffers"), {
          createdAt: timestamp,
          offererId: userData.id,
          opponentId: selectedOpponent.userId,
          opponentName: `${selectedOpponent.firstName} ${selectedOpponent.lastName}`,
          leagueId: activeLeague.id,
          halfTimeReminderSent: false,
          status: "pending",
        });

        // Yanıt verilmesi gereken son tarihi hesapla
        const respondBy = new Date(timestamp);
        respondBy.setDate(respondBy.getDate() + activeLeague.maxDaysToRespond);
        const respondByString = respondBy.toLocaleString("tr-TR", {
          dateStyle: "long",
          timeStyle: "short",
        });

        // E-posta gönderimi
        let emailStatus = "Başarısız";
        try {
          const emailResponse = await sendEmail({
            to: selectedOpponent.email,
            subject: "Yeni Maç Teklifi",
            text: `Merhaba ${selectedOpponent.firstName},
    
    ${userData.firstName} ${userData.lastName} size bir maç teklifi gönderdi. 
    Lütfen teklifi en geç ${respondByString} tarihine kadar yanıtlayın.
    
    Teklif Detayları:
    - Gönderen: ${userData.firstName} ${userData.lastName}
    - Teklif Tarihi: ${timestamp.toLocaleString("tr-TR")}
    
    Sisteme giriş yaparak teklifinizi inceleyebilir ve yanıtlayabilirsiniz.
    
    İyi oyunlar!
    tenisportali.com
    Tenis Portalı Ekibi`,
          });
          if (emailResponse) {
            emailStatus = "Başarılı";
          }
        } catch (error) {
          console.error("E-posta gönderiminde hata:", error.message);
          alert(
            "E-posta gönderiminde bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz."
          );
        }

        // Bildirimleri hem rakibe hem teklif yapana gönder
        const notifications = [
          {
            recipientId: selectedOpponent.userId,
            message: `${userData.firstName} ${userData.lastName} size maç teklifi gönderdi. Lütfen ${activeLeague.maxDaysToRespond} gün içinde yanıtlayınız.`,
            date: timestamp,
            status: "unread",
            subject: "Maç Teklifi",
          },
          {
            recipientId: userData.id,
            message: `Maç teklifi ${selectedOpponent.firstName} ${selectedOpponent.lastName} oyuncusuna gönderildi.`,
            date: timestamp,
            status: "unread",
            subject: "Maç Teklifi",
          },
        ];
        try {
          await Promise.all(
            notifications.map(async (notification) => {
              const userNotificationRef = doc(
                db,
                "users",
                notification.recipientId
              );
              const notificationsCollectionRef = collection(
                userNotificationRef,
                "notifications"
              );
              await addDoc(notificationsCollectionRef, notification);
            })
          );
        } catch (error) {
          console.log("Bildirimde Hata:", error);
        }

        // Admin log kaydı ekle
        try {
          await addDoc(collection(db, "adminLogs"), {
            createdAt: timestamp,
            eventType: "MatchOffer",
            eventDetails: `Oyuncu: ${userData.firstName} ${userData.lastName} (${userData.id})
Rakip: ${selectedOpponent.firstName} ${selectedOpponent.lastName} (${selectedOpponent.userId})
Yanıt Süresi: ${activeLeague.maxDaysToRespond} gün
Oynama Süresi: ${activeLeague.maxDaysToPlay} gün
E-posta Durumu: ${emailStatus}`,
            status: "unread",
          });
        } catch (error) {
          console.log("Log hatası : ", error);
        }

        // Onay ekranı için teklif detaylarını kaydet
        setConfirmationDetails({
          opponentName: `${selectedOpponent.firstName} ${selectedOpponent.lastName}`,
          respondBy: respondByString,
          offerTime: timestamp.toLocaleString("tr-TR"),
        });

        // Teklifin başarıyla gönderildiği ekranı göster

        setActiveStep(5);
        // console.log("active 5 sonu");
        // alert("Teklif başarıyla gönderildi!");
        // navigate("/notifications");
      } catch (error) {
        console.error("Error sending match offer:", error);

        // Admin log kaydı ekle (hata durumunda)
        await addDoc(collection(db, "adminLogs"), {
          createdAt: new Date(),
          eventType: "MatchOfferError",
          eventDetails: `Hata oluştu: ${error.message}`,
          status: "unread",
        });

        alert("Teklif gönderilirken bir hata oluştu.");
      } finally {
        setIsSubmitting(false); // İşlem kilidini kaldır.
      }
    }
  };

  const handleCancelOffer = async (offerId, offererId, opponentId) => {
    try {
      if (!offererId || !opponentId) {
        throw new Error("Teklif ID veya rakip oyuncu ID eksik");
        return;
      }
      // Firestore teklif referansı oluştur
      const offerRef = doc(db, "matchOffers", offerId);

      // Teklifin durumunu "cancelled" olarak güncelle
      await updateDoc(offerRef, {
        status: "cancelled",
        updateTime: new Date(),
      });

      // Rakip oyuncunun bilgilerini al
      const opponentRef = doc(db, "users", opponentId);
      const opponentDoc = await getDoc(opponentRef);

      if (!opponentDoc.exists()) {
        console.error("Rakip oyuncu bilgisi bulunamadı.");
        return;
      }

      const opponentData = opponentDoc.data();

      // Bildirimleri oluştur
      const notifications = [
        {
          recipientId: opponentId,
          message: `${userData.firstName} ${userData.lastName} maç teklifini iptal etti.`,
          date: new Date(),
          status: "unread",
          subject: "Maç Teklifi İptal Edildi",
        },
        {
          recipientId: userData.id,
          message: `Maç teklifiniz ${opponentData.firstName} ${opponentData.lastName} oyuncusuna iptal edildi.`,
          date: new Date(),
          status: "unread",
          subject: "Teklif İptali Onaylandı",
        },
      ];

      // Bildirimleri Firestore'a ekle
      await Promise.all(
        notifications.map(async (notification) => {
          const userNotificationRef = doc(
            db,
            "users",
            notification.recipientId
          );
          const notificationsCollectionRef = collection(
            userNotificationRef,
            "notifications"
          );
          await addDoc(notificationsCollectionRef, notification);
        })
      );

      // E-posta gönderimi
      await sendEmail({
        to: opponentData.email,
        subject: "Maç Teklifi İptal Edildi",
        text: `Merhaba ${opponentData.firstName},\n\n${userData.firstName} ${userData.lastName} tarafından yapılan maç teklifi iptal edilmiştir.\n\nİyi oyunlar!`,
      });

      await sendEmail({
        to: userData.email,
        subject: "Teklif İptali Onaylandı",
        text: `Merhaba ${userData.firstName},\n\n${opponentData.firstName} ${opponentData.lastName} oyuncusuna yaptığınız maç teklifi başarıyla iptal edilmiştir.\n\nİyi oyunlar!`,
      });

      // Kullanıcıya başarı mesajı göster
      alert("Teklif başarıyla iptal edildi.");

      // İptal edilen teklifi listeden kaldır
      setUserPendingOffers((prevOffers) =>
        prevOffers.filter((offer) => offer.id !== offerId)
      );
    } catch (error) {
      console.error("Teklif iptali sırasında hata oluştu:", error.message);
      alert(
        "Teklif iptali sırasında bir hata oluştu. Lütfen tekrar deneyiniz."
      );
    }
  };

  const handleNextStep = () => setActiveStep((prev) => prev + 1);
  const handlePrevStep = () => setActiveStep((prev) => Math.max(prev - 1, 0));

  const handleNewOffer = () => setActiveStep(1);
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </div>
      </div>
    );
  // console.log(userPendingOffers);

  return (
    <div className="container mt-4">
      {activeLeague ? (
        activeStep === 0 ? (
          <div>
            <h3>Mevcut Teklifler</h3>
            {receivedOffers.length > 0 ? (
              <>
                <p>
                  Size yapılmış teklifler var. Lütfen bu teklifleri yanıtlayın.
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Gönderen</th>
                      <th>Teklif Tarihi</th>
                      <th>Son Geçerlilik</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receivedOffers.map((offer) => (
                      <tr key={offer.id}>
                        <td>{offer.offererName}</td>
                        <td>
                          {new Date(
                            offer.createdAt.seconds * 1000
                          ).toLocaleString("tr-TR")}
                        </td>
                        <td>
                          {new Date(
                            offer.createdAt.seconds * 1000 +
                              activeLeague.maxDaysToRespond *
                                24 *
                                60 *
                                60 *
                                1000
                          ).toLocaleString("tr-TR")}
                        </td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={() => navigate(`/match_rew`)}
                          >
                            Teklife Git
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : userPendingOffers.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Rakip</th>
                    <th>Teklif Tarihi</th>
                    <th>Son Geçerlilik</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {userPendingOffers.map((offer) => (
                    <tr key={offer.id}>
                      <td>{offer.opponentName}</td>
                      <td>
                        {new Date(
                          offer.createdAt.seconds * 1000
                        ).toLocaleString("tr-TR")}
                      </td>
                      <td>
                        {new Date(
                          offer.createdAt.seconds * 1000 +
                            activeLeague.maxDaysToRespond * 24 * 60 * 60 * 1000
                        ).toLocaleString("tr-TR")}
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            handleCancelOffer(
                              offer.id,
                              offer.offererId,
                              offer.opponentId
                            )
                          }
                        >
                          İptal Et
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Şu anda gönderilmiş bir teklifiniz bulunmamaktadır.</p>
            )}
            <button
              className="btn btn-primary mt-3"
              onClick={() => handleNewOffer()}
            >
              Yeni Maç Teklifi Oluştur
            </button>
          </div>
        ) : (
          <Carousel
            activeIndex={activeStep - 1}
            indicators={false}
            controls={false}
          >
            <Carousel.Item>
              <LeagueRules
                activeLeague={activeLeague}
                onNextStep={handleNextStep}
              />
            </Carousel.Item>
            <Carousel.Item>
              <ExcuseCheck
                participants={participants}
                userExcuse={userExcuse}
                onNextStep={handleNextStep}
                onPrevStep={() => setActiveStep(0)} // İlk ekrana geri dön
              />
            </Carousel.Item>
            <Carousel.Item>
              <OfferPlayerList
                participants={participants}
                userId={userData.id}
                onNextStep={handleNextStep}
                onPrevStep={() => setActiveStep(1)} // Mevcut teklif ekranına dön
                activeLeague={activeLeague}
                userPendingOffers={userPendingOffers}
                sendMatchOffer={sendMatchOffer}
              />
            </Carousel.Item>
            <Carousel.Item>
              <SendMatchOffer
                activeLeague={activeLeague}
                userId={userData.id}
                onPrevStep={handlePrevStep}
                selectedOpponent={selectedOpponent}
                offerAccepted={offerAccepted}
                handleSendOffer={handleSendOffer}
                setOfferAccepted={setOfferAccepted}
              />
            </Carousel.Item>
            <Carousel.Item>
              {/* Teklifin başarıyla gönderildiği onay ekranı */}
              <div className="d-flex justify-content-center align-items-center vh-95 bg-dark text-white">
                <div className="text-center p-4">
                  <h3>Teklif Başarıyla Gönderildi!</h3>
                  {confirmationDetails && (
                    <>
                      <p>Rakip Oyuncu: {confirmationDetails.opponentName}</p>
                      <p>Teklif Tarihi: {confirmationDetails.offerTime}</p>
                      <p>
                        Yanıt Süresi: En geç {confirmationDetails.respondBy}{" "}
                        tarihine kadar.
                      </p>
                    </>
                  )}
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => navigate("/")}
                  >
                    Ana Sayfaya Dön
                  </button>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        )
      ) : (
        <div className="alert alert-warning">
          Aktif bir lige kayıtlı değilsiniz ya da liginiz artık aktif değil.
        </div>
      )}
    </div>
  );
}
export default MatchMainOffer;
