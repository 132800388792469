import React, { useState } from "react";

const MatchOfferWizard = ({ show, handleClose, opponent, onConfirm }) => {
  const [step, setStep] = useState(1);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const confirmOffer = () => {
    onConfirm(opponent);
    handleClose();
  };

  return (
    <div
      className={`modal ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Maç Teklifi</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            {step === 1 && (
              <>
                <p>
                  {opponent.firstName} {opponent.lastName} adlı oyuncuya maç
                  teklif edeceksiniz.
                </p>
                <button className="btn btn-primary" onClick={nextStep}>
                  Devam Et
                </button>
              </>
            )}

            {step === 2 && (
              <>
                <p>Onaylıyor musunuz?</p>
                <p>
                  Rakip: {opponent.firstName} {opponent.lastName}{" "}
                </p>
                <p>
                  Rakibinizle 1 hafta içinde maçınızı yapmanız gerekmektedir.
                </p>
                <button className="btn btn-secondary" onClick={prevStep}>
                  Geri
                </button>
                <button className="btn btn-success" onClick={confirmOffer}>
                  Teklifi Gönder
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchOfferWizard;
