// src/components/UserUpdate.js
import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import { cityList } from "../cityList";

const UserUpdate = ({ user, onClose, onSuccess }) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [birthdate, setBirthdate] = useState(user.birthdate);
  const [city, setCity] = useState(user.city || "");
  const [phone, setPhone] = useState(user.phone || "");
  const [role, setRole] = useState(user.role || "");
  const [gender, setGender] = useState(user.gender || "");
  const [currentUserId, setCurrentUserId] = useState("");

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentUserId(user.uid);
    }
  }, []);

  const handleUpdateUser = async () => {
    try {
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        firstName,
        lastName,
        email,
        birthdate,
        phone,
        role,
        city,
        gender,
        updatedAt: Timestamp.now(),
        updatedBy: currentUserId, // Giriş yapan kullanıcının ID'si
      });
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error updating user: ", error);
    }
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  return (
    <div>
      <div className="mb-3">
        <label className="form-label">İsim</label>
        <input
          type="text"
          className="form-control"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Soyisim</label>
        <input
          type="text"
          className="form-control"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Email</label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Doğum Tarihi</label>
        <input
          type="date"
          className="form-control"
          value={birthdate}
          onChange={(e) => setBirthdate(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Cinsiyet</label>
        <select
          className="form-select"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          required
        >
          <option value="">Seçiniz</option>
          <option value="male">Erkek</option>
          <option value="female">Kadın</option>
          <option value="other">Diğer</option>
        </select>
      </div>
      <div className="mb-3">
        <label className="form-label">Telefon</label>
        <input
          type="text"
          className="form-control"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label>İl:</label>
        <select
          value={city}
          onChange={handleCityChange}
          className="form-control"
          required
        >
          <option value="">Bir il seçin</option>
          {cityList.map((city) => (
            <option key={city.code} value={city.code}>
              {city.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Rol</label>
        <select
          className="form-select"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="">Seçiniz</option>
          <option value="Super Admin">Super Admin</option>
          <option value="Admin">Admin</option>
          <option value="Moderator">Moderator</option>
          <option value="User">User</option>
          <option value="Guest">Guest</option>
          <option value="Oyuncu">Oyuncu</option>
        </select>
      </div>
      <button className="btn btn-primary me-2" onClick={handleUpdateUser}>
        Güncelle
      </button>
      <button className="btn btn-secondary ms-4" onClick={onClose}>
        İptal
      </button>
    </div>
  );
};

export default UserUpdate;
