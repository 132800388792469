import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";

function LeagueList() {
  const [leagues, setLeagues] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [userApplication, setUserApplication] = useState(null);
  const [userLeagues, setUserLeagues] = useState([]);

  useEffect(() => {
    // Kullanıcı bilgilerini çek
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData({ ...userDoc.data(), userId: user.uid });
        }
      }
    };
    fetchUserProfile();
  }, []);

  useEffect(() => {
    // Kullanıcının katıldığı ligleri getir
    const fetchUserLeagues = async () => {
      if (userData) {
        try {
          const userLeaguesSnapshot = await getDocs(
            query(
              collection(db, "leagueParticipants"),
              where("userId", "==", userData.userId)
            )
          );
          const leagues = userLeaguesSnapshot.docs.map(
            (doc) => doc.data().leagueId
          );
          setUserLeagues(leagues);
        } catch (error) {
          console.error("Kullanıcı ligleri alınırken hata oluştu:", error);
        }
      }
    };
    fetchUserLeagues();
  }, [userData]);

  useEffect(() => {
    // Ligleri getir ve her lig için katılımcı sayısını al
    const fetchLeagues = async () => {
      try {
        if (userData) {
          const leaguesSnapshot = await getDocs(
            query(
              collection(db, "leagues"),
              where("isActive", "==", true),
              where("gender", "==", userData.gender)
            )
          );
          const leaguesData = await Promise.all(
            leaguesSnapshot.docs.map(async (leagueDoc) => {
              const leagueData = { id: leagueDoc.id, ...leagueDoc.data() };

              // Ligin sona erip ermediğini kontrol et
              if (
                leagueData.endDate &&
                new Date(leagueData.endDate) < new Date()
              ) {
                return null;
              }

              const participantsSnapshot = await getDocs(
                query(
                  collection(db, "leagueParticipants"),
                  where("leagueId", "==", leagueDoc.id)
                )
              );
              leagueData.participantsCount = participantsSnapshot.size;
              return leagueData;
            })
          );
          setLeagues(leaguesData.filter((league) => league !== null));
        }
      } catch (error) {
        console.error("Ligler alınırken hata oluştu:", error);
      }
    };
    if (userData) {
      fetchLeagues();
    }
  }, [userData]);

  useEffect(() => {
    // Kullanıcının mevcut başvurularını getir
    const fetchUserApplication = async () => {
      if (userData) {
        try {
          const applicationSnapshot = await getDocs(
            query(
              collection(db, "leagueApplications"),
              where("userId", "==", userData.userId),
              where("status", "==", "pending")
            )
          );
          if (!applicationSnapshot.empty) {
            setUserApplication(applicationSnapshot.docs[0].data());
          }
        } catch (error) {
          console.error("Başvuru alınırken hata oluştu:", error);
        }
      }
    };
    fetchUserApplication();
  }, [userData]);

  const handleJoinRequest = async (league) => {
    setSelectedLeague(league);
    setShowModal(true);
  };

  const confirmJoinRequest = async () => {
    try {
      // Lige katılma isteğini leagueApplications koleksiyonuna ekle
      await addDoc(collection(db, "leagueApplications"), {
        userId: userData.userId,
        leagueId: selectedLeague.id,
        status: "pending",
        createdAt: new Date(),
      });

      // Admin log kaydı ekle
      await addDoc(collection(db, "adminLogs"), {
        eventType: "JoinRequest",
        eventDetails: `${userData.firstName} ${userData.lastName} ${selectedLeague.name} ligine katılmak istiyor.`,
        createdAt: new Date(),
        status: "unread",
      });

      // Kullanıcıya bildirim gönder
      const notification = {
        recipientId: userData.userId,
        message: `Lige katılma isteğiniz ${selectedLeague.name} ligine başarıyla gönderildi. Admin onayını bekleyiniz.`,
        date: new Date(),
        status: "unread",
        subject: "Lige Katılım İsteği",
      };

      const userNotificationRef = doc(db, "users", notification.recipientId);
      const notificationsCollectionRef = collection(
        userNotificationRef,
        "notifications"
      );
      await addDoc(notificationsCollectionRef, {
        message: notification.message,
        date: notification.date,
        status: notification.status,
        subject: notification.subject,
      });

      // Başvuru durumunu güncelle
      setUserApplication({
        leagueId: selectedLeague.id,
        status: "pending",
      });

      setShowModal(false);
    } catch (error) {
      console.error("Lige katılma isteği gönderilirken hata oluştu:", error);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Katılabileceğiniz Ligler</h2>
      {leagues.length === 0 ? (
        <Card className="text-center mt-4 text-info">
          <Card.Body>
            <Card.Title>Uygun Bir Lig Bulunamadı!</Card.Title>
            <Card.Text>
              Şu anda katılabileceğiniz uygun bir lig bulunmamaktadır. Lütfen
              daha sonra tekrar kontrol ediniz.
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <Accordion defaultActiveKey="0">
          {leagues.map((league, index) => (
            <Accordion.Item eventKey={index} key={league.id}>
              <Accordion.Header>{league.name}</Accordion.Header>
              <Accordion.Body>
                <p>
                  <strong>Başlangıç Tarihi:</strong>{" "}
                  {league.startDate
                    ? new Date(league.startDate).toLocaleDateString("tr-TR")
                    : "Belirtilmemiş"}
                </p>
                <p>
                  <strong>Bitiş Tarihi:</strong>{" "}
                  {league.endDate
                    ? new Date(league.endDate).toLocaleDateString("tr-TR")
                    : "Belirtilmemiş"}
                </p>
                <p>
                  <strong>Aktif mi?:</strong>{" "}
                  {league.isActive ? "Evet" : "Hayır"}
                </p>
                <p>
                  <strong>Oyuncu Sayısı:</strong>{" "}
                  {league.participantsCount || 0}
                </p>
                <p>
                  <strong>Kurallar:</strong>
                  <ul>
                    <li>
                      Maçlar {league.setCount} set üzerinden oynanacaktır.
                    </li>
                    <li>
                      Beraberlik durumunda{" "}
                      {league.setDecisionsFormat === "Set"
                        ? "karar seti uygulanacaktır"
                        : "Süper Tie-break uygulanacaktır"}
                      .
                    </li>
                    <li>
                      Puan beraberliğinde{" "}
                      {league.pointType === "deciding"
                        ? "karar sayısı"
                        : "avantaj sayısı"}{" "}
                      uygulanacaktır.
                    </li>
                  </ul>
                </p>
                {userLeagues.includes(league.id) ? (
                  <Button variant="secondary" disabled>
                    Zaten bu lige kayıtlısınız
                  </Button>
                ) : userApplication?.leagueId === league.id ? (
                  <Button variant="secondary" disabled>
                    Başvurunuz değerlendirme aşamasında
                  </Button>
                ) : league.isActive ? (
                  <Button
                    variant="success"
                    onClick={() => handleJoinRequest(league)}
                  >
                    Lige Katıl
                  </Button>
                ) : (
                  <Button variant="secondary" disabled>
                    Bu lig aktif değil
                  </Button>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}

      {/* Katılma Onay Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Lige Katılma Onayı</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {selectedLeague?.name} ligine katılma isteğinde bulunmak üzeresiniz.
            Talebiniz incelenecek ve uygun bulunursa kabul edilecektir. Bu süre
            zarfında başka bir lig için katılım talebinde bulunamayacaksınız.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Vazgeç
          </Button>
          <Button variant="primary" onClick={confirmJoinRequest}>
            Onayla ve Katıl
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LeagueList;
