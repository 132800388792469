import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import LeagueApplicationForm from "../component/LeagueApplicationForm";
import "bootstrap/dist/css/bootstrap.min.css";
import ShowModal from "../component/ShowModal1";
import MatchOfferModal from "../component/MatchOfferModal";
import DashCard from "../component/DashCard";
import MatchOfferWizard from "../component/MatchOfferWizard";
import UseUserNotifications from "../component/UseUserNotifications";
import ExcuseStatus from "../component/ExcuseStatus";
import ProfileCard from "../component/ProfileCard";
import NotificationCard from "../component/NotificationCard";

function UserDashboard() {
  const [userLeague, setUserLeague] = useState(null);
  const [leagueStatus, setLeagueStatus] = useState(null);
  const [leagueName, setLeagueName] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState(null);
  const [oyuncuId, setOyuncuId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("");
  const [excuse, setExcuse] = useState("");
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  const [announcements, setAnnouncements] = useState([]);
  const [readAnnouncements, setReadAnnouncements] = useState(new Set());
  const [showModal, setShowModal] = useState(false);
  const [showExcuseUser, setExcuseUserModal] = useState(false);
  const [showAllNotification, setAllNotificationModal] = useState(false);

  const [allAnnouncements, setAllAnnouncements] = useState([]);

  const today = new Date().toISOString().split("T")[0];

  const [showModal_offer, setShowModal_offer] = useState(false);
  const [selectedOpponent, setSelectedOpponent] = useState(null);
  const [showWizard, setShowWizard] = useState(false);

  //Modal Kısımları
  const openOfferModal = () => setShowModal_offer(true);
  const closeOfferModal = () => setShowModal_offer(false);

  const openExcuseModal = () => setExcuseUserModal(true);
  const closeExcuseUserModal = () => setExcuseUserModal(false);

  const openAllNotificationModal = () => setAllNotificationModal(true);
  const closeAllNotificationModal = () => setAllNotificationModal(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setOyuncuId(user.uid);
          setFirstName(data.firstName || "");
          setLastName(data.lastName || "");
          setPhone(data.phone || "");
          setBirthdate(data.birthdate || "");
          setGender(data.gender || "");
        }
      }
    };

    const fetchUserLeague = async () => {
      try {
        if (oyuncuId) {
          // console.log("oyuncu burada var mı :", oyuncuId); //////////////////////////////
          const leagueParticipantsSnapshot = await getDocs(
            query(
              collection(db, "leagueParticipants"),
              where("userId", "==", oyuncuId)
            )
          );

          const leagueData = leagueParticipantsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (leagueData.length > 0) {
            const leagueId = leagueData[0].leagueId;
            setUserLeague(leagueId);

            const leagueDocRef = doc(db, "leagues", leagueId);
            const leagueDoc = await getDoc(leagueDocRef);
            if (leagueDoc.exists()) {
              const league = leagueDoc.data();
              setLeagueName(league.name);
              setLeagueStatus(league.status);
            }
          }
        }
      } catch (err) {
        console.error("Hata:", err);
        setError("Lig verileri yüklenirken bir hata oluştu.");
      }
    };

    const fetchParticipants = async () => {
      if (userLeague) {
        try {
          const participantsSnapshot = await getDocs(
            query(
              collection(db, "leagueParticipants"),
              where("leagueId", "==", userLeague)
            )
          );

          const leagueParticipants = await Promise.all(
            participantsSnapshot.docs.map(async (participantDoc) => {
              const participantData = participantDoc.data();
              const userDocRef = doc(db, "users", participantData.userId);
              const userDoc = await getDoc(userDocRef);

              if (userDoc.exists()) {
                const userData = userDoc.data();
                return {
                  id: participantDoc.id,
                  userId: participantData.userId,
                  points: participantData.points || 0,
                  rank: participantData.rank,
                  firstName: userData.firstName || "Adı Yok",
                  lastName: userData.lastName || "Soyadı Yok",
                  excuse: userData.excuseType || "",
                };
              } else {
                return null;
              }
            })
          );

          setParticipants(leagueParticipants.filter(Boolean));
        } catch (err) {
          console.error("Hata:", err);
          setError("Lig katılımcıları yüklenirken bir hata oluştu.");
        }
      }
    };

    fetchUserProfile();
    fetchUserLeague();
    fetchParticipants();
  }, [oyuncuId, userLeague]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const allAnnouncements = await getDocs(collection(db, "announcements"));
      const readAnnouncements = await getDocs(
        query(
          collection(db, "readAnnouncements"),
          where("userId", "==", auth.currentUser?.uid)
        )
      );

      //Tüm duyurular announsment a kaydedilir, okunanlar ise readAnnounsmenta.
      //Burada okunmuş duyuruların ıdleri toplanıyor
      const readIds = readAnnouncements.docs.map(
        (doc) => doc.data().announcementId
      );
      // console.log("readIds", auth.currentUser?.uid, readIds);

      //okunmamış idleri tespit etmek için tüm duyuruların için okunanlara bakılıyor, içinde olmayanlar okunmamış olarak kabul ediliyor.
      const unreadAnnouncements = allAnnouncements.docs.filter(
        (doc) => !readIds.includes(doc.id)
      );

      //okunmamış duyurular burada tutulur
      setAnnouncements(
        unreadAnnouncements.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchAnnouncements();
  }, [today]);

  //Duyuruları Okundu Yapma
  const handleMarkAsRead = async (announcementId) => {
    try {
      await addDoc(collection(db, "readAnnouncements"), {
        userId: auth.currentUser?.uid,
        announcementId: announcementId,
        readAt: new Date(),
      });
      // Duyuru listesini güncelleme (örneğin, yeniden yükleyerek veya durumu güncelleyerek)
      setAnnouncements((prev) =>
        prev.filter((item) => item.id !== announcementId)
      );
    } catch (error) {
      console.error("Okundu olarak işaretlenirken hata oluştu:", error);
    }
  };

  //Tüm duyuruları alma
  const handleShowAllAnnouncements = async () => {
    const allAnnouncements = await getDocs(collection(db, "announcements"));
    const readAnnouncements = await getDocs(
      query(
        collection(db, "readAnnouncements"),
        where("userId", "==", auth.currentUser?.uid)
      )
    );

    const readIds = readAnnouncements.docs.map(
      (doc) => doc.data().announcementId
    );
    const announcementsWithStatus = allAnnouncements.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      isRead: readIds.includes(doc.id),
    }));

    setAllAnnouncements(announcementsWithStatus);
    setShowModal(true);
  };

  const handleOfferSelect = (opponent) => {
    setSelectedOpponent(opponent);
    setShowModal(false);
    setShowWizard(true);
  };

  //şehre göre duyuru
  const fetchCityBasedAnnouncements = async () => {
    const announcementsSnapshot = await getDocs(
      query(
        collection(db, "announcements"),
        where("city", "==", city) // Kullanıcının şehri ile eşleşen duyuruları getirin
      )
    );
    setAnnouncements(announcementsSnapshot.docs.map((doc) => doc.data()));
  };

  const handleConfirmOffer = async (opponent, currentUserId) => {
    try {
      // Teklif verilerini ID'ler ile hazırlama
      const offerData = {
        offererId: oyuncuId, // Teklifi yapanın ID'si
        opponentId: opponent.userId, // Teklif edilen kişinin ID'si

        status: "pending", // Başlangıçta teklif durumu "pending"
        createdAt: new Date(),
      };

      // Teklif veritabanına kaydediliyor
      const docRef = await addDoc(collection(db, "matchOffers"), offerData);
      // console.log("Teklif başarıyla gönderildi! Teklif ID'si:", docRef.id);

      // Teklif giden kişiye bildirim oluşturma
      const notificationData = {
        type: "matchOffer",
        message: `Yeni bir maç teklifi aldınız. ${offerData.createdAt} tarihinden itibaren en geç 1 hafta içinde rakibinizle maçınızı yapmanız gerekmektedir.`,
        offerId: docRef.id, // Teklif ID'si
        status: "unread",
        createdAt: new Date(),
      };

      // Bildirimi teklif edilen kişinin notifications koleksiyonuna ekleme
      await addDoc(
        collection(db, `users/${opponent.userId}/notifications`),
        notificationData
      );

      alert("Teklif başarıyla gönderildi ve bildirim oluşturuldu!");
    } catch (error) {
      console.error(
        "Teklif gönderilirken veya bildirim oluşturulurken hata oluştu:",
        error
      );
      alert(
        "Teklif gönderilirken veya bildirim oluşturulurken bir hata oluştu."
      );
    }
  };

  if (error) {
    return <div>{error}</div>;
  }
  const closeModal = () => setShowModal(false);

  return (
    <div className="container text-center mt-5">
      <h1 className="mb-4">Merhaba, {firstName || "Kullanıcı"}! 🎾</h1>
      <div className="motivation-quote mb-4">
        <blockquote className="blockquote text-center">
          <p>
            Bugün antrenman için harika bir gün. Hedeflerine bir adım daha
            yaklaşmak için hazır mısın?
          </p>
        </blockquote>
      </div>
      <div className="row">
        {/* Aktif Lig Bilgisi Kartı */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body">
              <h5 className="card-header">Katıldığınız Lig</h5>
              <p className="card-text">
                Lig durumu ve sıralamalar hakkında bilgi alın.
              </p>
              {userLeague ? (
                <div className="p-3 mb-2 bg-info-subtle text-info-emphasis">
                  <h4>Aktif Lig: {leagueName}</h4>
                  <p>
                    Lig Durumu:
                    {leagueStatus === "active" ? " Aktif" : " Başlamadı"}
                  </p>
                </div>
              ) : (
                <div className="text-center">
                  <p>Henüz bir lige katılmadınız.</p>
                  <a href="/leagues" className="btn btn-primary">
                    Liglere Göz At
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Yeni Bildirimler Kartı */}
        <NotificationCard userId={oyuncuId} />

        {/* Duyurular Kartı */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body">
              <h5 className="card-header">Duyurular</h5>
              <p className="card-text"></p>
              <ul className="list-group">
                {announcements.map((announcement) => (
                  <li key={announcement.id} className="list-group-item">
                    <strong>{announcement.title}</strong> -{" "}
                    {announcement.content}
                    <div className="d-flex justify-content-between">
                      <small>Bitiş Tarihi: {announcement.endDate}</small>
                      {!readAnnouncements.has(announcement.id) && (
                        <button
                          className="btn btn-link"
                          onClick={() => handleMarkAsRead(announcement.id)}
                        >
                          Okundu olarak işaretle
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              <button
                className="btn btn-info mt-3"
                onClick={handleShowAllAnnouncements}
              >
                Tüm Duyuruları Gör
              </button>
              {/* <ShowModal
                isOpen={showModal}
                closeModal={closeModal}
                announcements={allAnnouncements}
              /> */}
            </div>
          </div>
        </div>
        {/* Bekleyen Maç Teklifleri */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body">
              <h5 className="card-header">Bekleyen Maç Teklifleri</h5>
              <p className="card-text">Maç Teklifleri</p>
              <button
                className="btn btn-info"
                onClick={() => navigate("/match_rew")}
              >
                Teklifleri Gör
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {/* MAzeretim Var Kartı */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body">
              <h5 className="card-header">Mazaret Girişi</h5>
              <p className="card-text">
                Hastalık, sakatlık vb. mazeret girişlerinizi yapabilirsiniz. Bu
                tarihler arasında maç tekliflerine kapalı kalırsınız.
              </p>
              <p className="card-text"></p>
              <button
                className="btn btn-info"
                onClick={() => openExcuseModal()}
              >
                Mazeret Gir
              </button>
            </div>
          </div>
        </div>
        {/* Yaklaşan Olaylar Kartı */}
        <DashCard header="Yaklaşan Olaylar" content="Yaklaşan Olaylar" />
        {/* Maç Teklifi Kartı */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body">
              <h5 className="card-header">Maç Teklifi</h5>
              <p className="card-text">Bir maç yapalım mı?</p>
              <button
                className="btn btn-info mt-3"
                variant="primary"
                onClick={() => navigate("/match")}
              >
                Teklif Et
              </button>
              {/* Yeni link ekleme */}
              {/* <Link
                to="/match"
                className="btn btn-link mt-2"
                // state={{ userInfo: userInfo, leagueId: userLeague }}
              >
                Maç Teklif Sayfasına Git
              </Link> */}
              <p className="card-text"></p>
            </div>
          </div>
        </div>
        {/* Lig Katılımcıları Kartı */}
        <div className="col-sm-4 col-md-4 col-sm-6 mb-4">
          <div className="card h-100 d-flex flex-column">
            <div className="card-body">
              <h5 className="card-header">Lig Katılımcıları</h5>
              <p className="card-text">
                Lige katılan tüm oyuncuları burada görüntüleyebilirsiniz.
              </p>
              {participants.length > 0 && (
                <div className="leaderboard animate__animated animate__fadeInUp">
                  <h4>Lig Katılımcıları</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Oyuncu</th>
                        <th>Sıra No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {participants
                        .sort((a, b) => a.rank - b.rank) // rank'e göre sıralama işlemi
                        .map((participant, index) => (
                          <tr
                            key={index}
                            className="animate__animated animate__fadeInLeft"
                          >
                            <td>{participant.rank}</td>
                            <td>
                              {participant.firstName} {participant.lastName}
                            </td>
                            <td>{participant.rank}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal_offer && (
        <ShowModal title="Maç Teklifi" onClose={closeOfferModal}>
          {participants.length > 0 ? (
            <MatchOfferModal
              players={participants}
              showModal={showModal_offer} // showModal prop'u eklendi
              closeModal={closeOfferModal}
              onOfferSelect={handleOfferSelect} // Teklif seçme işlevi eklendi
              user_id={oyuncuId}
            />
          ) : (
            <p>Oyuncu bulunamadı</p> // Eğer katılımcı yoksa bir uyarı gösterilir
          )}
        </ShowModal>
      )}

      {showAllNotification && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Bildirimler</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeAllNotificationModal}
                ></button>
              </div>
              <div className="modal-body">
                <NotificationCard allNotification={allNotifications} />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeAllNotificationModal}
                >
                  Kapat
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showWizard && (
        <MatchOfferWizard
          show={showWizard}
          handleClose={() => setShowWizard(false)}
          opponent={selectedOpponent}
          onConfirm={handleConfirmOffer}
        />
      )}

      {/* Mazeret Giriş Modalı */}
      {showExcuseUser && (
        <div
          className="modal show fade"
          tabIndex="-1"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          onClick={closeExcuseUserModal}
        >
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Mazeret Girişi</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeExcuseUserModal}
                ></button>
              </div>
              <div className="modal-body">
                <ExcuseStatus
                  onClose={closeExcuseUserModal}
                  onSuccess={closeExcuseUserModal}
                  ligId={userLeague}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <ShowModal title="Tüm Duyurular" onClose={closeModal}>
          <ul className="list-group">
            {allAnnouncements.map((announcement) => (
              <li
                key={announcement.id}
                className={`list-group-item ${
                  announcement.isRead ? "bg-primary-subtle" : "bg-info-subtle"
                }`}
              >
                <strong>{announcement.title}</strong> - {announcement.content}
                <div className="d-flex justify-content-between">
                  <small>Bitiş Tarihi: {announcement.endDate}</small>
                  {!announcement.isRead && (
                    <button
                      className="btn btn-link"
                      onClick={() => handleMarkAsRead(announcement.id)}
                    >
                      Okundu olarak işaretle
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </ShowModal>
      )}
    </div>
  );
}

export default UserDashboard;
