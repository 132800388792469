import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS dosyasını içe aktar
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Header = ({ userInfo }) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [userRol, setUserRol] = useState(null);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/login"); // Çıkış yaptıktan sonra login sayfasına yönlendir
    } catch (error) {
      console.error("Çıkış hatası:", error);
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();

          setUserId(user.uid);
          setUserRol(data.role);
        }
      }
    };
    fetchUserProfile();
  }, [userRol]);

  return (
    <header className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/home">
          DEFİ Ligi
        </a>

        {/* Navbar toggle button for mobile */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <a className="nav-link" href="/home">
                Ana Sayfa
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/profile">
                Profil
              </a>
            </li> */}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ayarlar
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/profile">
                    Profil
                  </a>
                </li>
                {userRol === "Admin" && (
                  <>
                    <li>
                      <a className="dropdown-item" href="/adm-mail">
                        Eposta Gönder
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        liste3
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Maç İşlemleri
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="match">
                    Teklif Et
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/match_rew">
                    Teklifleri Gör
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/leagues">
                    Ligler
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/notifications">
                Bildirimler
              </a>
            </li>

            {userRol === "Admin" && (
              <li className="nav-item">
                <a className="nav-link" href="/admin">
                  Admin
                </a>
              </li>
            )}
            {/* <li className="nav-item">
              <a className="nav-link" href="/dashboard">
                Dashboard
              </a>
            </li> */}
          </ul>

          {/* User info and logout button */}
          <div className="d-flex align-items-center">
            <span className="me-2">
              {userInfo
                ? `${userInfo.firstName} ${userInfo.lastName} ${userInfo.rol}`
                : auth.currentUser.email}
            </span>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={handleLogout}
            >
              Çıkış Yap
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
