import React from "react";

function DashCard({ header, content }) {
  return (
    <>
      <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
        <div className="card h-100 d-flex flex-column">
          <div className="card-body">
            <h5 className="card-header">{header}</h5>
            <p className="card-text">{content} </p>
            <p className="card-text"></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashCard;
