import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

const SettingsManagement = ({ userInfo }) => {
  const [settings, setSettings] = useState({
    loginTimeout: 30,
    matchCompletionDeadline: 7,
  });

  // İlk renderde ayarları Firestore'dan yükle
  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDoc = doc(db, "settings", "general");
      const settingsSnapshot = await getDoc(settingsDoc);
      if (settingsSnapshot.exists()) {
        setSettings(settingsSnapshot.data());
      }
    };
    fetchSettings();
  }, []);

  // Yetki kontrolü
  // if (userInfo && !["Super Admin", "Admin"].includes(userInfo.rol)) {
  //   return <Navigate to="/unauthorized" replace />;
  // }

  const handleInputChange = (e) => {
    setSettings({
      ...settings,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveSettings = async () => {
    const settingsDocRef = doc(db, "settings", "general");
    await setDoc(settingsDocRef, settings);
  };

  return (
    <div className="container mt-4">
      <h2>General Settings</h2>
      <div className="mb-3">
        <label>Login Timeout (minutes):</label>
        <input
          type="number"
          name="loginTimeout"
          value={settings.loginTimeout}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label>Match Completion Deadline (days):</label>
        <input
          type="number"
          name="matchCompletionDeadline"
          value={settings.matchCompletionDeadline}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <button className="btn btn-primary" onClick={handleSaveSettings}>
        Save Settings
      </button>
    </div>
  );
};

export default SettingsManagement;
