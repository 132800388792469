import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import UserAdd from "../component/UserAdd";
import UserUpdate from "../component/UserUpdate";
import UserDelete from "../component/UserDelete";
import DisplayUser from "../component/DisplayUser";
import "bootstrap/dist/css/bootstrap.min.css";

import RequireAdmin from "./RequireAdmin";
import { useNavigate } from "react-router-dom";

const UserManagement = ({ userInfo }) => {
  const [users, setUsers] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(null);
  const [showDeleteUser, setShowDeleteUser] = useState(null);
  const [showDisplayUser, setShowDisplayUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const navigate = useNavigate();

  const fetchUsers = async () => {
    const userCollection = collection(db, "users");
    const userSnapshot = await getDocs(userCollection);
    const userList = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(userList);
    setFilteredUsers(userList);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.gender &&
          user.gender.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  }, [searchTerm, users]);

  const closeAddUserModal = () => setShowAddUser(false);
  const closeUpdateUserModal = () => setShowUpdateUser(null);
  const closeDeleteUserModal = () => setShowDeleteUser(null);
  const closeDisplayUserModal = () => setShowDisplayUser(null);

  const handleSort = (key) => {
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
    setFilteredUsers(sortedUsers);
    setSortBy(key);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <RequireAdmin userInfo={userInfo}>
      <div className="container mt-4">
        <h2>Kullanıcı İşlemleri</h2>

        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Ara (Ad, Soyad, E-posta, Cinsiyet)"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th onClick={() => handleSort("firstName")}>Ad</th>
              <th onClick={() => handleSort("lastName")}>Soyad</th>
              <th onClick={() => handleSort("gender")}>Cinsiyet</th>
              <th onClick={() => handleSort("email")}>E-posta</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user) => (
              <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.gender}</td>
                <td>{user.email}</td>
                <td className="align-center">
                  <button
                    onClick={() => setShowUpdateUser(user)}
                    className="btn btn-link text-primary p-0 me-2"
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                  <button
                    onClick={() => setShowDeleteUser(user)}
                    className="btn btn-link text-danger p-0 me-2"
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                  <button
                    onClick={() => setShowDisplayUser(user)}
                    className="btn btn-link text-info p-0 me-2"
                  >
                    <i className="bi bi-eye "></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          className="btn btn-primary mb-2"
          onClick={() => setShowAddUser(true)}
        >
          <i className="bi bi-plus-circle-fill">Kullanıcı Ekle </i>
        </button>

        {/* Pagination */}
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={handlePreviousPage}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(totalPages).keys()].map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${
                  pageNumber + 1 === currentPage ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={handleNextPage}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>

        {/* Kullanıcı Ekleme Modalı */}
        {showAddUser && (
          <div
            className="modal fade show"
            tabIndex="-1"
            style={{
              display: "block",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={closeAddUserModal}
          >
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Yeni Kullanıcı Ekle</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeAddUserModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <UserAdd
                    onClose={closeAddUserModal}
                    onSuccess={fetchUsers}
                    userInfo={userInfo}
                    setUsers={setUsers}
                    users={users}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Kullanıcı Güncelleme Modalı */}
        {showUpdateUser && (
          <div
            className="modal show fade"
            tabIndex="-1"
            style={{
              display: "block",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={closeUpdateUserModal}
          >
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Kullanıcı Düzenle</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeUpdateUserModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <UserUpdate
                    user={showUpdateUser}
                    onClose={closeUpdateUserModal}
                    onSuccess={fetchUsers}
                    userInfo={userInfo}
                    setUsers={setUsers}
                    users={users}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Kullanıcı Bilgilerini Gösterme Modalı */}
        {showDisplayUser && (
          <div
            className="modal show fade"
            tabIndex="-1"
            style={{
              display: "block",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={closeDisplayUserModal}
          >
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Kullanıcı Bilgileri</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeDisplayUserModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <DisplayUser
                    user={showDisplayUser} // Kullanıcı bilgilerini geçir
                    onClose={closeDisplayUserModal}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Kullanıcı Silme Modalı */}
        {showDeleteUser && (
          <div
            className="modal show fade"
            tabIndex="-1"
            style={{
              display: "block",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={closeDeleteUserModal}
          >
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Kullanıcı Silme</h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={closeDeleteUserModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <UserDelete
                    user={showDeleteUser}
                    onClose={closeDeleteUserModal}
                    onSuccess={fetchUsers}
                    userInfo={userInfo}
                    setUsers={setUsers}
                    users={users}
                  />
                </div>
                <div className="modal-footer">
                  <span>Dikkat! Bu işlem geri alınamaz.</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </RequireAdmin>
  );
};

export default UserManagement;
