// src/components/UserAdd.js
import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import RequireAdmin from "./RequireAdmin";

const UserAdd = ({ onClose, onSuccess, userInfo }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");

  const handleAddUser = async () => {
    try {
      await addDoc(collection(db, "users"), {
        firstName,
        lastName,
        email,
        birthdate,
        phone,
        role,
        gender,
        createdAt: Timestamp.now(),
        createdBy: userInfo.id, // Giriş yapan kullanıcının ID'si
      });
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error adding user: ", error);
    }
  };

  return (
    <RequireAdmin userInfo={userInfo}>
      <div>
        <div className="mb-3">
          <label className="form-label">İsim</label>
          <input
            type="text"
            className="form-control"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Soyisim</label>
          <input
            type="text"
            className="form-control"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Doğum Tarihi</label>
          <input
            type="date"
            className="form-control"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Cinsiyet</label>
          <select
            className="form-select"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="">Seçiniz</option>
            <option value="male">Erkek</option>
            <option value="female">Kadın</option>
            <option value="other">Diğer</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Telefon</label>
          <input
            type="text"
            className="form-control"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Rol</label>
          <select
            className="form-select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="">Seçiniz</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Admin">Admin</option>
            <option value="Moderator">Moderator</option>
            <option value="User">User</option>
            <option value="Guest">Guest</option>
            <option value="Oyuncu">Oyuncu</option>
          </select>
        </div>
        <button className="btn btn-primary me-3" onClick={handleAddUser}>
          Ekle
        </button>
        <button className="btn btn-secondary ms-2" onClick={onClose}>
          İptal
        </button>
      </div>
    </RequireAdmin>
  );
};

export default UserAdd;
