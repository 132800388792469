import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

const LeagueParticipants = ({ league_id, onClose, onSuccess }) => {
  const [participants, setParticipants] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const participantsSnapshot = await getDocs(
          query(
            collection(db, "leagueParticipants"),
            where("leagueId", "==", league_id)
          )
        );
        const usersSnapshot = await getDocs(collection(db, "users"));

        const usersMap = {};
        usersSnapshot.docs.forEach((doc) => {
          usersMap[doc.id] = doc.data();
        });

        const leagueParticipants = participantsSnapshot.docs.map((doc) => {
          const participantData = doc.data();
          const user = usersMap[participantData.userId];
          return {
            id: doc.id,
            userName: user
              ? `${user.firstName} ${user.lastName}`
              : "Bilinmeyen Kullanıcı",
            joinDate: participantData.joinDate
              ? participantData.joinDate.toDate().toLocaleDateString()
              : "-",
            rank: participantData.rank || 0, // rank değeri ekliyoruz
          };
        });

        // Rank değerine göre sıralıyoruz
        leagueParticipants.sort((a, b) => a.rank - b.rank);

        setParticipants(leagueParticipants);
        setAllUsers(
          usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      } catch (err) {
        console.error("Hata:", err);
        setError("Katılımcılar yüklenirken bir hata oluştu.");
      }
    };

    fetchParticipants();
  }, [league_id]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      setFilteredUsers(
        allUsers.filter((user) =>
          `${user.firstName} ${user.lastName}`
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setFilteredUsers([]);
    }
  };

  const addParticipant = async (user) => {
    try {
      // Kullanıcının zaten bu ligde olup olmadığını kontrol ediyoruz
      const participantSnapshot = await getDocs(
        query(
          collection(db, "leagueParticipants"),
          where("leagueId", "==", league_id),
          where("userId", "==", user.id)
        )
      );

      // Eğer kullanıcı zaten katılımcıysa, işlem yapılmasın
      if (!participantSnapshot.empty) {
        setError("Bu kullanıcı zaten bu ligde mevcut.");
        return;
      }

      // Yeni katılımcının rank değerini mevcut katılımcı sayısına göre ayarlıyoruz
      const newRank = participants.length + 1;

      // Yeni katılımcıyı ekliyoruz
      await addDoc(collection(db, "leagueParticipants"), {
        leagueId: league_id,
        userId: user.id,
        status: "added",
        joinDate: new Date(),
        rank: newRank, // rank alanı ekliyoruz
      });

      // Katılımcı listesini güncelliyoruz
      setParticipants((prev) => [
        ...prev,
        {
          id: user.id,
          userName: `${user.firstName} ${user.lastName}`,
          joinDate: new Date().toLocaleDateString(),
          rank: newRank,
        },
      ]);

      // Başarılı işlem sonrası callback fonksiyonu
      onSuccess();
    } catch (err) {
      console.error("Kullanıcı eklenirken hata oluştu:", err);
      setError("Kullanıcı eklenirken bir hata oluştu.");
    }
  };

  // Katılımcının sırasını yukarı veya aşağı hareket ettiren işlev ve rank güncelleme
  const moveParticipant = async (index, direction) => {
    const newParticipants = [...participants];
    if (direction === "up" && index > 0) {
      [newParticipants[index - 1], newParticipants[index]] = [
        newParticipants[index],
        newParticipants[index - 1],
      ];
    } else if (direction === "down" && index < participants.length - 1) {
      [newParticipants[index + 1], newParticipants[index]] = [
        newParticipants[index],
        newParticipants[index + 1],
      ];
    } else {
      return;
    }

    // Yeni sıralamayı set ediyoruz
    setParticipants(newParticipants);

    // Firestore'da güncellenmiş rank değerlerini kaydediyoruz
    try {
      await Promise.all(
        newParticipants.map((participant, idx) =>
          updateDoc(doc(db, "leagueParticipants", participant.id), {
            rank: idx + 1,
          })
        )
      );
    } catch (err) {
      console.error("Rank güncellenirken hata oluştu:", err);
      setError("Rank güncellenirken bir hata oluştu.");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h3>Lige Kayıtlı Kullanıcılar</h3>

      <div className="mb-3">
        <input
          type="text"
          placeholder="Kullanıcı arayın..."
          value={searchTerm}
          onChange={handleSearch}
          className="form-control"
        />
        {filteredUsers.length > 0 && (
          <ul className="list-group mt-2">
            {filteredUsers.map((user) => (
              <li
                key={user.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {user.firstName} {user.lastName}
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => addParticipant(user)}
                >
                  Ekle
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {participants.length === 0 ? (
        <p>Henüz kaydolmuş katılımcı bulunmamaktadır.</p>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Kullanıcı Adı</th>
              <th>Kayıt Tarihi</th>
              <th>Rank</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant, index) => (
              <tr key={participant.id}>
                <td>{index + 1}</td>
                <td>{participant.userName}</td>
                <td>{participant.joinDate}</td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => moveParticipant(index, "up")}
                    disabled={index === 0}
                  >
                    ↑
                  </button>
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => moveParticipant(index, "down")}
                    disabled={index === participants.length - 1}
                  >
                    ↓
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LeagueParticipants;
