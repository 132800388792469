// src/components/UserDelete.js
import React from "react";
import { db } from "../firebase";
import { doc, deleteDoc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";

const UserDelete = ({ user, onClose, onSuccess }) => {
  const handleDeleteUser = async () => {
    try {
      const userRef = doc(db, "users", user.id);
      await deleteDoc(userRef);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  return (
    <div>
      <p>
        {user.firstName} {user.lastName} adlı kullanıcıyı silmek istediğinize
        emin misiniz?
      </p>
      <button className="btn btn-danger me-2" onClick={handleDeleteUser}>
        Sil
      </button>
      <button className="btn btn-secondary ms-2" onClick={onClose}>
        İptal
      </button>
    </div>
  );
};

export default UserDelete;
