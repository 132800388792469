import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { cityList } from "../cityList";

const Profile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("Oyuncu");
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Kullanıcının mevcut profil bilgilerini al
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          // Kullanıcının mevcut profil bilgilerini form alanlarına doldur
          const data = userDoc.data();
          setFirstName(data.firstName || "");
          setLastName(data.lastName || "");
          setPhone(data.phone || "");
          setBirthdate(data.birthdate || "");
          setGender(data.gender || "");
          setCity(data.city || "");
          setIsUpdating(true); // Profil mevcutsa güncelleme moduna geç
        }
      }
    };

    fetchUserProfile();
  }, []);

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    try {
      const timestamp = new Date();
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);

        if (isUpdating) {
          // Mevcut profili güncelle
          await updateDoc(userDocRef, {
            firstName,
            lastName,
            phone,
            birthdate,
            gender,
            city,
            role,
            updatedBy: user.uid,
            updatedAt: timestamp,
          });

          // AdminLog'a profil güncelleme kaydı ekle
          await addDoc(collection(db, "adminLogs"), {
            createdAt: timestamp,
            eventType: "ProfileUpdate",
            eventDetails: `Kullanıcı ${user.email} (${user.uid}) profilini güncelledi.`,
            status: "unread",
          });
        } else {
          // Yeni profil oluştur
          await setDoc(userDocRef, {
            firstName,
            lastName,
            phone,
            birthdate,
            gender,
            role,
            city,
            email: user.email,
            createdBy: user.uid,
            createdAt: timestamp,
            updatedBy: user.uid,
            updatedAt: timestamp,
          });

          // AdminLog'a yeni kullanıcı kaydı ekle
          await addDoc(collection(db, "adminLogs"), {
            createdAt: timestamp,
            eventType: "ProfileCreation",
            eventDetails: `Yeni kullanıcı kaydı: ${user.email} (${user.uid})`,
            status: "unread",
          });
        }
        navigate("/dashboard"); // Profil tamamlandıktan sonra yönlendirme
      }
    } catch (error) {
      console.error("Profil kaydedilemedi:", error);
    }
  };

  return (
    <div className="container-sm">
      <h2>Profilinizi Tamamlayın</h2>
      {/* Uyarı Mesajları */}
      {isUpdating ? (
        <div className="alert alert-info mt-3" role="alert">
          Lütfen profil bilgilerinizi güncel tutunuz.
        </div>
      ) : (
        <div className="alert alert-warning mt-3" role="alert">
          Profil bilgilerinin tamamlanması zorunludur. Lütfen gerekli alanları
          doldurunuz.
        </div>
      )}
      <form onSubmit={handleProfileSubmit}>
        <div className="mb-3">
          <label>Ad</label>
          <input
            type="text"
            className="form-control"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label>Soyad</label>
          <input
            type="text"
            className="form-control"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label>Telefon</label>
          <input
            type="tel"
            className="form-control"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label>Doğum Tarihi</label>
          <input
            type="date"
            className="form-control"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Cinsiyet</label>
          <select
            className="form-select"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Seçiniz</option>
            <option value="male">Erkek</option>
            <option value="female">Kadın</option>
            <option value="other">Diğer</option>
          </select>
        </div>
        <div className="form-group">
          <label>İl:</label>
          <select
            value={city}
            onChange={handleCityChange}
            className="form-control"
            required
          >
            <option value="">Bir il seçin</option>
            {cityList.map((city) => (
              <option key={city.code} value={city.code}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          {isUpdating ? "Güncelle" : "Kaydet"}
        </button>
      </form>
    </div>
  );
};

export default Profile;
