import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";

const AddMatch = () => {
  const [player1, setPlayer1] = useState("");
  const [player2, setPlayer2] = useState("");
  const [matchDate, setMatchDate] = useState("");
  const [score, setScore] = useState("");
  const [status, setStatus] = useState("planlanmış");

  const handleAddMatch = async () => {
    try {
      await addDoc(collection(db, "matches"), {
        player1,
        player2,
        matchDate: Timestamp.fromDate(new Date(matchDate)),
        score,
        status,
      });
      alert("Maç başarıyla eklendi!");
      setPlayer1("");
      setPlayer2("");
      setMatchDate("");
      setScore("");
      setStatus("planlanmış");
    } catch (error) {
      console.error("Maç eklenirken hata oluştu: ", error);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Yeni Maç Ekle</h2>
      <form>
        <div className="mb-3">
          <label>Oyuncu 1</label>
          <input
            type="text"
            className="form-control"
            value={player1}
            onChange={(e) => setPlayer1(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Oyuncu 2</label>
          <input
            type="text"
            className="form-control"
            value={player2}
            onChange={(e) => setPlayer2(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Maç Tarihi ve Saati</label>
          <input
            type="datetime-local"
            className="form-control"
            value={matchDate}
            onChange={(e) => setMatchDate(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Skor</label>
          <input
            type="text"
            className="form-control"
            value={score}
            onChange={(e) => setScore(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Durum</label>
          <select
            className="form-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="planlanmış">Planlanmış</option>
            <option value="oynanmış">Oynanmış</option>
            <option value="iptal">İptal</option>
          </select>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddMatch}
        >
          Maç Ekle
        </button>
      </form>
    </div>
  );
};

export default AddMatch;
