import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

const RoleManagement = ({ userInfo }) => {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);

  // userInfo'nun dolmasını bekle
  useEffect(() => {
    if (userInfo) {
      setLoadingUserInfo(false); // userInfo yüklendiğinde durum false yapılıyor
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");

      const userSnapshot = await getDocs(usersCollection);

      const userList = userSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setUsers(userList);
      setLoadingUsers(false);
    };
    fetchUsers();
  }, []);
  // console.log(users);
  const handleRoleChange = async (userId, newRole) => {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, { role: newRole });
    setUsers(
      users.map((user) =>
        user.id === userId ? { ...user, role: newRole } : user
      )
    );
  };

  // Ekranda yalnızca yükleniyor mesajı gösterilirken herhangi bir yönlendirme yapma
  if (loadingUsers || loadingUserInfo) return <div>Loading...</div>;

  // Kullanıcı bilgisi yüklendikten sonra rol kontrolü yap
  if (!["Super Admin", "Admin"].includes(userInfo.rol)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <div className="container mt-4">
      <h2>Role Management</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Current Role</th>
            <th>Change Role</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.firstName}</td>
              <td>{user.role}</td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) => handleRoleChange(user.id, e.target.value)}
                >
                  <option value="">...</option>
                  <option value="Super Admin">Super Admin</option>
                  <option value="Admin">Admin</option>
                  <option value="Moderator">Moderator</option>
                  <option value="User">User</option>
                  <option value="Guest">Guest</option>
                  <option value="Oyuncu">Oyuncu</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoleManagement;
