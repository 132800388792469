import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

function ExcuseStatus({ onClose, onSuccess, ligId }) {
  const [excuseType, setExcuseType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  //   console.log("geldim");
  const fetchExcuseStatus = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "execuses", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setExcuseType(data.excuseType || "");
        setStartDate(data.startDate || "");
        setEndDate(data.endDate || "");
      }
    }
  };

  const handleSaveExcuse = async () => {
    const user = auth.currentUser;
    if (user) {
      // Tarihlerin geçerliliğini kontrol et
      if (startDate >= endDate) {
        alert("Mazeret başlangıç tarihi, bitiş tarihinden önce olmalıdır.");
        return;
      }

      // Çakışma kontrolü için mevcut mazeretleri getir
      const excuseQuery = query(
        collection(db, "excuses"),
        where("userId", "==", user.uid),
        where("endDate", ">", startDate) // Başlangıç tarihinden önce bitmeyen mazeretler
      );

      const excuseSnapshot = await getDocs(excuseQuery);

      // Çakışan bir tarih var mı kontrol et
      if (!excuseSnapshot.empty) {
        const existingExcuse = excuseSnapshot.docs[0].data();
        alert(
          `Bu tarih aralığında başka bir mazeret var. Mevcut mazeret ${existingExcuse.startDate} ile ${existingExcuse.endDate} arasında. Lütfen yeni bir tarih aralığı girin.`
        );
        return;
      }

      // Yeni mazereti excuses koleksiyonuna kaydet
      const excuseDocRef = doc(collection(db, "excuses"));
      await setDoc(excuseDocRef, {
        userId: user.uid,
        excuseType: excuseType,
        startDate: startDate,
        endDate: endDate,
        createdAt: new Date(),
      });

      // Bildirim gönderme
      await sendNotificationToOtherPlayers(
        user.uid,
        excuseType,
        startDate,
        endDate
      );

      alert("Durum güncellendi!");
      onSuccess(); // İsteğe bağlı: başarılı olduğunda dışarıdan gelen onSuccess fonksiyonu çağrılabilir
    }
  };

  // Ligdeki diğer oyunculara bildirim gönderme
  const sendNotificationToOtherPlayers = async (
    userId,
    excuseType,
    startDate,
    endDate
  ) => {
    try {
      const leagueId = ligId; // Lig ID'sini burada kullanın
      const playersRef = collection(db, "leagueParticipants");

      // Lig ID'si ve status 'added' olan belgeleri filtreleyin
      const q = query(playersRef, where("leagueId", "==", leagueId));
      const playersSnapshot = await getDocs(q);
      if (playersSnapshot.empty) {
        console.log("Bu ligde kayıtlı oyuncu bulunamadı veya sorgu boş döndü.");
        return;
      }

      // Mazeretli kişinin bilgilerini alıyoruz
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();
      const playerName = `${userData.firstName} ${userData.lastName}`; // Adı ve soyadı

      playersSnapshot.forEach(async (doc) => {
        const participant = doc.data();
        const playerId = participant.userId;

        if (playerId !== userId) {
          // Kendisine bildirim göndermemek için kontrol
          console.log(playerId, userId);
          try {
            await addDoc(collection(db, `users/${playerId}/notifications`), {
              message: `${playerName} adlı oyuncu, ${excuseType} nedeniyle ${startDate} - ${endDate} tarihleri arasında mazeret bildirmiştir.`,
              status: "unread",
              date: new Date(),
            });
            // Konsola başarılı bir bildirim mesajı yazdırıyoruz
            // console.log(
            //   `Bildirim gönderildi: ${playerName} adlı oyuncu, ${excuseType} mazeret durumu: ${startDate} - ${endDate}`
            // );
          } catch (notificationError) {
            console.error(
              `Bildirim gönderilemedi (userId: ${playerId}):`,
              notificationError
            );
          }
        }
      });
    } catch (error) {
      console.error("Bildirim gönderme sırasında hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchExcuseStatus();
    // console.log(excuseType, startDate, endDate);
  }, []);

  return (
    <div className="container mt-5">
      <h1>Durum Bilgisi Girişi</h1>
      <form>
        <div className="form-group">
          <label>Mazeret Cinsi:</label>
          <select
            value={excuseType}
            onChange={(e) => setExcuseType(e.target.value)}
            className="form-control"
          >
            <option value="">Bir mazeret cinsi seçin</option>
            <option value="Sakatlık">Sakatlık</option>
            <option value="Tatil">Tatil</option>
            <option value="Hastalık">Hastalık</option>
            <option value="Diğer">Diğer</option>
          </select>
        </div>
        <div className="form-group">
          <label>Başlangıç Tarihi:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Bitiş Tarihi:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control"
          />
        </div>
        <button
          type="button"
          onClick={handleSaveExcuse}
          className="btn btn-primary"
        >
          Durumu Kaydet
        </button>
        <button className="btn btn-secondary ms-4" onClick={onClose}>
          İptal
        </button>
      </form>
    </div>
  );
}

export default ExcuseStatus;
