import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  doc,
  query,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  where,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTrash,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Notifications() {
  const [userData, setUserData] = useState("");
  const [allNotifications, setAllNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData({ ...data, userId: user.uid });
        }
      }
    };
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (userData) {
      const fetchNotifications = async () => {
        try {
          const notificationsQuery = query(
            collection(db, `users/${userData.userId}/notifications`),
            where("status", "!=", "deleted")
          );

          const allNotificationsSnapshot = await getDocs(notificationsQuery);
          const allNotificationsData = allNotificationsSnapshot.docs.map(
            (doc) => ({
              id: doc.id,
              ...doc.data(),
            })
          );

          // Tüm bildirimleri setAllNotifications ile kaydet
          setAllNotifications(allNotificationsData);
        } catch (error) {
          console.error("Bildirimler alınırken hata oluştu:", error);
        }
      };
      fetchNotifications();
    }
  }, [userData.userId]);

  // Sayfalama işlemi
  const totalPages = Math.ceil(allNotifications.length / itemsPerPage);
  const paginatedNotifications = allNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleDeleteNotification = async (id) => {
    try {
      const notificationRef = doc(
        db,
        `users/${userData.userId}/notifications`,
        id
      );
      await updateDoc(notificationRef, { status: "deleted" });
      setAllNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    } catch (error) {
      console.error("Bildirim silinirken hata oluştu:", error);
    }
  };

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);

    // Bildirimi okundu olarak işaretle
    if (notification.status !== "read") {
      try {
        const notificationRef = doc(
          db,
          `users/${userData.userId}/notifications`,
          notification.id
        );
        await updateDoc(notificationRef, { status: "read" });
        setAllNotifications((prevNotifications) =>
          prevNotifications.map((noti) =>
            noti.id === notification.id ? { ...noti, status: "read" } : noti
          )
        );
      } catch (error) {
        console.error(
          "Bildirim okundu olarak işaretlenirken hata oluştu:",
          error
        );
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  return (
    <div className="container">
      <h5 className="card-header">Bildirimler</h5>
      <p className="card-text">Tüm bildirimleri buradan takip edebilirsiniz.</p>
      <div>
        <h5 className="card-header d-flex align-items-center text-white bg-dark">
          Tüm Bildirimler{" "}
          <span className="ms-2">
            {allNotifications.filter((noti) => noti.status === "unread")
              .length > 0 && (
              <span className="badge bg-danger me-2">
                {
                  allNotifications.filter((noti) => noti.status === "unread")
                    .length
                }
              </span>
            )}
          </span>
        </h5>
        {allNotifications.length > 0 && (
          <ul className="list-group list-group-flush">
            {paginatedNotifications.map((notification) => (
              <li
                key={notification.id}
                className={`list-group-item d-flex justify-content-between align-items-center 
              ${
                notification.status === "read"
                  ? "bg-secondary text-muted"
                  : "bg-primary text-white"
              }`}
                onClick={() => handleNotificationClick(notification)}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <h6>
                    {notification.subject === "Maç Teklifi" ? (
                      <FontAwesomeIcon icon={faEnvelope} />
                    ) : (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    )}{" "}
                    {notification.subject}
                  </h6>
                  <p className="mb-1">{notification.message}</p>
                  <small className="text-muted">
                    {notification.date
                      ? new Date(
                          notification.date.seconds * 1000
                        ).toLocaleString("tr-TR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Tarih bilgisi yok"}
                  </small>
                </div>
                <button
                  className="btn btn-link p-0 ms-2 text-danger"
                  onClick={(e) => {
                    e.stopPropagation(); // Bildirim satırına tıklanmasını önler
                    handleDeleteNotification(notification.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} /> Sil
                </button>
              </li>
            ))}
          </ul>
        )}
        {allNotifications.length === 0 && (
          <p className="text-muted">Yeni bildirim yok.</p>
        )}

        {/* Pagination Controls */}
        {allNotifications.length > 0 && (
          <div className="d-flex justify-content-between mt-3">
            <button
              className="btn btn-outline-light"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Önceki
            </button>
            <span className="text-white">
              Sayfa {currentPage} / {totalPages}
            </span>
            <button
              className="btn btn-outline-light"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Sonraki
            </button>
          </div>
        )}
      </div>

      {/* Modal for Notification Details */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedNotification && selectedNotification.subject}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedNotification && (
            <>
              <p>{selectedNotification.message}</p>
              <small className="text-muted">
                {selectedNotification.date
                  ? new Date(
                      selectedNotification.date.seconds * 1000
                    ).toLocaleString("tr-TR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "Tarih bilgisi yok"}
              </small>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Notifications;
