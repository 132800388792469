import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  addDoc,
  deleteDoc,
  doc,
  where,
  orderBy,
  startAt,
  endAt,
  serverTimestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";

function LeagueModerators({
  leagueId,
  leagueName,
  addModerator,
  removeModerator,
  onClose,
}) {
  const [moderators, setModerators] = useState([]);
  const [newModeratorInput, setNewModeratorInput] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // Fetch moderators from Firestore
  const fetchModerators = async () => {
    try {
      const moderatorsCollection = collection(
        db,
        `leagues/${leagueId}/moderators`
      );
      const moderatorsSnapshot = await getDocs(moderatorsCollection);
      const moderatorsData = await Promise.all(
        moderatorsSnapshot.docs.map(async (moderatorDoc) => {
          const userDocRef = doc(db, `users/${moderatorDoc.data().userId}`);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          return {
            id: moderatorDoc.id,
            ...moderatorDoc.data(),
            fullName: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
          };
        })
      );
      setModerators(moderatorsData);
    } catch (error) {
      console.error("Moderatörler getirilirken hata oluştu:", error);
    }
  };

  useEffect(() => {
    fetchModerators();
  }, [leagueId]);

  // Fetch user suggestions based on input
  const fetchUserSuggestions = async (input) => {
    if (input.length < 3) {
      setUserSuggestions([]);
      return;
    }
    try {
      const usersCollection = collection(db, "users");

      const firstNameQuery = query(
        usersCollection,
        where("firstName", ">=", input),
        where("firstName", "<=", input + "\uf8ff"),
        orderBy("firstName")
      );
      const lastNameQuery = query(
        usersCollection,
        where("lastName", ">=", input),
        where("lastName", "<=", input + "\uf8ff"),
        orderBy("lastName")
      );
      const emailQuery = query(
        usersCollection,
        where("email", ">=", input),
        where("email", "<=", input + "\uf8ff"),
        orderBy("email")
      );

      const [firstNameSnapshot, lastNameSnapshot, emailSnapshot] =
        await Promise.all([
          getDocs(firstNameQuery),
          getDocs(lastNameQuery),
          getDocs(emailQuery),
        ]);

      const users = new Map();

      firstNameSnapshot.docs.forEach((doc) =>
        users.set(doc.id, {
          id: doc.id,
          ...doc.data(),
          fullName: `${doc.data().firstName} ${doc.data().lastName}`,
        })
      );
      lastNameSnapshot.docs.forEach((doc) =>
        users.set(doc.id, {
          id: doc.id,
          ...doc.data(),
          fullName: `${doc.data().firstName} ${doc.data().lastName}`,
        })
      );
      emailSnapshot.docs.forEach((doc) =>
        users.set(doc.id, {
          id: doc.id,
          ...doc.data(),
          fullName: `${doc.data().firstName} ${doc.data().lastName}`,
        })
      );

      setUserSuggestions(Array.from(users.values()));
    } catch (error) {
      console.error("Kullanıcı önerileri getirilirken hata oluştu:", error);
    }
  };

  useEffect(() => {
    if (newModeratorInput.length >= 3) {
      fetchUserSuggestions(newModeratorInput);
    } else {
      setUserSuggestions([]);
    }
  }, [newModeratorInput]);

  // Handle adding a new moderator
  const handleAddModerator = async () => {
    if (!selectedUser) {
      alert("Lütfen bir kullanıcı seçin.");
      return;
    }
    try {
      // Add moderator to Firestore
      await addModerator(leagueId, selectedUser.id);
      setNewModeratorInput("");
      setSelectedUser(null);
      setUserSuggestions([]);
      fetchModerators();

      const now = new Date().toLocaleString("tr-TR", {
        timeZone: "Europe/Istanbul",
      });

      // Create notification for new moderator in user's notifications subcollection
      const userNotificationsCollection = collection(
        db,
        `users/${selectedUser.id}/notifications`
      );
      await addDoc(userNotificationsCollection, {
        date: now,
        message: `Siz ${leagueName} ligi için moderatör olarak atandınız.`,
        status: "unread",
        subject: "Moderator Yetkisi",
      });

      // Log event to admin collection
      const adminLogsCollection = collection(db, "adminLogs");
      await addDoc(adminLogsCollection, {
        eventType: "ModeratorAdded",
        eventDetails: `Kullanıcı ${selectedUser.fullName} (${selectedUser.email}) ${leagueName} ligi için moderatör olarak atandı.`,
        status: "unread",
        createdAt: now,
      });

      //   alert(
      //     "Seçilen kullanıcıya bildirim gönderildi ve olay admin paneline kaydedildi."
      //   );
    } catch (error) {
      console.error("Moderatör eklenirken hata oluştu:", error);
      alert("Moderatör eklenirken bir hata oluştu.");
    }
  };

  // Handle removing a moderator
  const handleRemoveModerator = async (moderatorId, moderatorName) => {
    try {
      await removeModerator(leagueId, moderatorId);
      fetchModerators();

      const now = new Date().toLocaleString("tr-TR", {
        timeZone: "Europe/Istanbul",
      });

      // Log event to admin collection
      const adminLogsCollection = collection(db, "adminLogs");
      await addDoc(adminLogsCollection, {
        eventType: "ModeratorRemoved",
        eventDetails: `Kullanıcı ${moderatorId} ${moderatorName} ${leagueName} liginden moderatör olarak kaldırıldı.`,
        status: "unread",
        createdAt: now,
      });
    } catch (error) {
      console.error("Moderatör silinirken hata oluştu:", error);
      alert("Moderatör silinirken bir hata oluştu.");
    }
  };

  return (
    <div>
      <h3>Lig Moderatörleri</h3>
      <ul className="list-group mb-3">
        {moderators.map((moderator) => (
          <li
            key={moderator.id}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            {moderator.fullName} ({moderator.email})
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() =>
                handleRemoveModerator(moderator.id, moderator.fullName)
              }
            >
              <i className="bi bi-trash"></i> Sil
            </button>
          </li>
        ))}
      </ul>

      <div className="input-group mb-3 position-relative">
        <input
          type="text"
          className="form-control"
          placeholder="Yeni moderatör adı, soyadı veya eposta"
          value={newModeratorInput}
          onChange={(e) => setNewModeratorInput(e.target.value)}
        />
        {userSuggestions.length > 0 && (
          <ul
            className="list-group position-absolute w-100"
            style={{ top: "100%", zIndex: 1000 }}
          >
            {userSuggestions.map((user) => (
              <li
                key={user.id}
                className="list-group-item list-group-item-action"
                onClick={() => {
                  setSelectedUser(user);
                  setNewModeratorInput(`${user.fullName} (${user.email})`);
                  setUserSuggestions([]);
                }}
              >
                {user.fullName} ({user.email})
              </li>
            ))}
          </ul>
        )}
        <button
          className="btn btn-outline-primary"
          onClick={handleAddModerator}
        >
          <i className="bi bi-plus-circle"></i> Ekle
        </button>
      </div>

      <button className="btn btn-secondary" onClick={onClose}>
        Kapat
      </button>
    </div>
  );
}

export default LeagueModerators;
