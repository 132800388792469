import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

const MatchList = () => {
  const [matches, setMatches] = useState([]);

  const fetchMatches = async () => {
    const matchCollection = collection(db, "matches");
    const matchSnapshot = await getDocs(matchCollection);
    const matchList = matchSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setMatches(matchList);
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  return (
    <div className="container mt-4">
      <h2>Maç Listesi</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Oyuncu 1</th>
            <th>Oyuncu 2</th>
            <th>Tarih</th>
            <th>Skor</th>
            <th>Durum</th>
          </tr>
        </thead>
        <tbody>
          {matches.map((match) => (
            <tr key={match.id}>
              <td>{match.player1}</td>
              <td>{match.player2}</td>
              <td>
                {new Date(match.matchDate.seconds * 1000).toLocaleString()}
              </td>
              <td>{match.score}</td>
              <td>{match.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchList;
