// src/component/DisplayUser.jsx
import React from "react";

const DisplayUser = ({ user, onClose }) => {
  return (
    <div>
      <h5>Kullanıcı Bilgileri</h5>
      <p>
        <strong>Ad:</strong> {user.firstName}
      </p>
      <p>
        <strong>Soyad:</strong> {user.lastName}
      </p>
      <p>
        <strong>Cinsiyet:</strong> {user.gender}
      </p>
      <p>
        <strong>E-posta:</strong> {user.email}
      </p>
      <p>
        <strong>User ID:</strong> {user.id}
      </p>
      <p>
        <strong>City:</strong> {user.city}
      </p>
      {/* Diğer gerekli bilgiler buraya eklenebilir */}
      <button className="btn btn-secondary" onClick={onClose}>
        Kapat
      </button>
    </div>
  );
};

export default DisplayUser;
