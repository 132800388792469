const OfferPlayerList = ({
  participants,
  userId,
  onNextStep,
  onPrevStep,
  activeLeague,
  userPendingOffers,
  sendMatchOffer,
}) => (
  <div className="container ">
    <div className="alert alert-info text-start mb-4">
      <p>
        <strong>Kural Hatırlatmaları:</strong>
      </p>
      <ul>
        <li>
          Sıralamanız ilk 10 içinde ise, yalnızca sizden en fazla{" "}
          <strong>3 sıra üstündeki</strong> oyunculara teklif yapabilirsiniz.
        </li>
        <li>
          Sıralamanız 10'dan büyük ise, yalnızca sizden en fazla{" "}
          <strong>5 sıra üstündeki</strong> oyunculara teklif yapabilirsiniz.
        </li>
        <li>
          Rakip oyuncunun aktif bir mazereti varsa, o oyuncuya teklif
          yapamazsınız.
        </li>
        <li>
          Bir oyuncuya aynı anda <strong>en fazla 2 teklif</strong> yapılabilir.
          Eğer bu sayı aşılmışsa, o oyuncuya teklif edemezsiniz.
        </li>
        <li>
          Aynı anda en fazla <strong>{activeLeague.maxOffers}</strong> oyuncuya
          teklif gönderebilirsiniz.
        </li>
      </ul>
    </div>
    <h3>Teklif Edilebilecek Oyuncular</h3>

    <div className="table-responsive">
      <table className="table table-striped table-success table-hover">
        <thead>
          <tr>
            <th scope="col">Sıralama</th>
            <th scope="col">Adı</th>
            <th scope="col">Durum</th>
            <th scope="col">Teklif</th>
          </tr>
        </thead>
        <tbody>
          {participants
            .sort((a, b) => a.rank - b.rank) // Rank değerine göre sıralama
            .filter((player) => {
              const userRank = participants.find(
                (p) => p.userId === userId
              )?.rank;

              if (userRank === undefined) return false;

              // 1. Şart: Teklif edilebilecek oyuncuları belirleme
              if (userRank <= 10) {
                // Kullanıcı sıralaması 10 veya daha az ise, en fazla 3 sıra üstündekilere teklif edebilir
                return (
                  player.rank < userRank &&
                  player.rank >= userRank - 3 &&
                  player.userId !== userId
                );
              } else {
                // Kullanıcı sıralaması 10'dan büyükse, en fazla 5 sıra üstündekilere teklif edebilir
                return (
                  player.rank < userRank &&
                  player.rank >= userRank - 5 &&
                  player.userId !== userId
                );
              }
            })
            .map((player) => {
              const userHasOfferedToThisPlayer = userPendingOffers.some(
                (offer) => offer.opponentId === player.userId
              );

              return (
                <tr key={player.userId}>
                  <td>{player.rank}</td>
                  <td>
                    {player.firstName} {player.lastName}
                  </td>
                  <td>
                    {player.excuses.length > 0 ? (
                      <span className="text-danger">Mazeretli</span>
                    ) : (
                      <span className="text-success">Müsait</span>
                    )}
                    {player.pendingOffers > 0 && (
                      <span className="text-danger ms-2">
                        ({player.pendingOffers} bekleyen teklif)
                      </span>
                    )}
                  </td>
                  <td>
                    {player.excuses.length === 0 &&
                    player.pendingOffers < 2 &&
                    !userHasOfferedToThisPlayer &&
                    userPendingOffers.length < activeLeague.maxOffers ? (
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => sendMatchOffer(player)}
                      >
                        Teklif Et
                      </button>
                    ) : (
                      <span className="text-primary p-3">
                        {userHasOfferedToThisPlayer
                          ? "Teklif Zaten Gönderildi"
                          : "Teklif Edilemez"}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>

    <button className="btn btn-info me-3" onClick={onPrevStep}>
      {"<"} Geri
    </button>
    <button className="btn btn-info" onClick={onNextStep}>
      İleri {">"}
    </button>
  </div>
);

export default OfferPlayerList;
