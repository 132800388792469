import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import Carousel from "react-bootstrap/Carousel";

function MatchOfferReviewCarousel({ userInfo, leagueId }) {
  const [userData, setUserData] = useState(null);
  const [receivedOffers, setReceivedOffers] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [offerAction, setOfferAction] = useState(""); // "accept" or "reject"
  const [userLeague, setUserLeague] = useState(null);
  const [leagueDatas, setLeagueDatas] = useState(null); // leagueDatas state tanımı
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Kullanıcı bilgilerini çekme
    const fetchUserProfile = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserData({
              ...userDoc.data(),
              id: user.uid,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserProfile();
  }, []);

  useEffect(() => {
    // Fetch the league the user is part of
    const fetchUserLeague = async () => {
      if (userData?.id) {
        try {
          const leagueParticipantsSnapshot = await getDocs(
            query(
              collection(db, "leagueParticipants"),
              where("userId", "==", userData.id)
            )
          );
          const leagueData = leagueParticipantsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserData({
            ...userData,
            rank: leagueData[0].rank,
          });
          if (leagueData.length > 0) {
            const leagueId = leagueData[0].leagueId;
            setUserLeague(leagueId);

            // Lig bilgilerini leagueDatas'a set etme
            const leagueDocRef = doc(db, "leagues", leagueId);
            const leagueDoc = await getDoc(leagueDocRef);
            if (leagueDoc.exists()) {
              setLeagueDatas(leagueDoc.data());
            }
          }
        } catch (error) {
          console.error("Error fetching user's league:", error);
        }
      }
    };
    fetchUserLeague();
  }, [userData]);

  useEffect(() => {
    // Kullanıcıya yapılmış maç tekliflerini çekme ve teklif eden kişinin bilgilerini ekleme
    const fetchReceivedOffers = async () => {
      if (userData?.id && userLeague) {
        try {
          const offersSnapshot = await getDocs(
            query(
              collection(db, "matchOffers"),
              where("opponentId", "==", userData.id),
              where("status", "==", "pending")
            )
          );

          const offers = await Promise.all(
            offersSnapshot.docs.map(async (offerDoc) => {
              const offerData = offerDoc.data();
              const offererDocRef = doc(db, "users", offerData.offererId);
              const offererDoc = await getDoc(offererDocRef);

              if (offererDoc.exists()) {
                const offererData = offererDoc.data();

                // Teklif eden kullanıcının rank değerini leagueParticipants'ten çekme
                const leagueParticipantSnapshot = await getDocs(
                  query(
                    collection(db, "leagueParticipants"),
                    where("userId", "==", offerData.offererId),
                    where("leagueId", "==", userLeague)
                  )
                );
                let offererRank = "Bilinmiyor";
                if (!leagueParticipantSnapshot.empty) {
                  offererRank = leagueParticipantSnapshot.docs[0].data().rank;
                }

                return {
                  id: offerDoc.id,
                  ...offerData,
                  offererFirstName: offererData.firstName || "Adı Yok",
                  offererLastName: offererData.lastName || "Soyadı Yok",
                  offererRank: offererRank,
                  createdAt: offerData.createdAt,
                };
              } else {
                return null;
              }
            })
          );

          setReceivedOffers(offers.filter((offer) => offer !== null));
          setLoading(false);
        } catch (error) {
          console.error("Error fetching received offers:", error);
        }
      }
    };
    fetchReceivedOffers();
  }, [userData, userLeague]);

  const handleSelectOffer = (offer) => {
    setSelectedOffer(offer);
    setActiveStep(1);
  };

  const handleActionSelection = (action) => {
    setOfferAction(action);
    setActiveStep(2);
  };

  const handleFinalConfirmation = async () => {
    if (!selectedOffer) return;

    try {
      const offerRef = doc(db, "matchOffers", selectedOffer.id);
      await updateDoc(offerRef, {
        status: offerAction === "accept" ? "accepted" : "rejected",
        updateTime: new Date(),
      });

      // Bekleyen diğer tekliflerin durumu "unavailable_due_to_other_offer" olarak güncellenecek
      const pendingOffersSnapshot = await getDocs(
        query(
          collection(db, "matchOffers"),
          where("opponentId", "==", userData.id),
          where("status", "==", "pending")
        )
      );
      pendingOffersSnapshot.docs.forEach(async (offerDoc) => {
        if (offerDoc.id !== selectedOffer.id) {
          await updateDoc(offerDoc.ref, {
            status: "unavailable_due_to_other_offer",
            updateTime: new Date(),
          });
        }
      });

      // Teklif reddedildiğinde lig sıralamasını güncelleme
      if (offerAction === "reject") {
        const offererRank = selectedOffer.offererRank;
        const userRank = userData.rank;

        // Teklifi reddeden kullanıcının rank değeri bir arttırılır
        const userParticipantRef = query(
          collection(db, "leagueParticipants"),
          where("userId", "==", userData.id),
          where("leagueId", "==", userLeague)
        );
        const userParticipantSnapshot = await getDocs(userParticipantRef);
        if (!userParticipantSnapshot.empty) {
          const userParticipantDoc = userParticipantSnapshot.docs[0];
          await updateDoc(userParticipantDoc.ref, {
            rank: userRank + 1,
          });
        }

        // Teklif yapan kullanıcının rank değeri, teklif reddeden kullanıcının eski rank değeri olarak güncellenir
        const offererParticipantRef = query(
          collection(db, "leagueParticipants"),
          where("userId", "==", selectedOffer.offererId),
          where("leagueId", "==", userLeague)
        );
        const offererParticipantSnapshot = await getDocs(offererParticipantRef);
        if (!offererParticipantSnapshot.empty) {
          const offererParticipantDoc = offererParticipantSnapshot.docs[0];
          await updateDoc(offererParticipantDoc.ref, {
            rank: userRank,
          });
        }

        // Diğer oyuncuların rank değeri bir arttırılır
        const otherParticipantsRef = query(
          collection(db, "leagueParticipants"),
          where("leagueId", "==", userLeague),
          where("rank", ">", offererRank),
          where("rank", "<", userRank)
        );
        const otherParticipantsSnapshot = await getDocs(otherParticipantsRef);
        otherParticipantsSnapshot.docs.forEach(async (participantDoc) => {
          await updateDoc(participantDoc.ref, {
            rank: participantDoc.data().rank + 1,
          });
        });
      }

      // Bildirimleri oluştur
      const notifications = [
        {
          recipientId: selectedOffer.offererId,
          message:
            offerAction === "accept"
              ? `${userData.firstName} ${userData.lastName} maç teklifinizi kabul etti.`
              : `${userData.firstName} ${userData.lastName} maç teklifinizi reddetti.`,
          date: new Date(),
          status: "unread",
          subject:
            offerAction === "accept"
              ? "Maç Teklifi Kabul Edildi"
              : "Maç Teklifi Reddedildi",
        },
        {
          recipientId: userData.id,
          message:
            offerAction === "accept"
              ? `Teklifi kabul ettiniz. Maç ${userLeague} liginde oynanacaktır.`
              : `${selectedOffer.firstName} ${selectedOffer.offererLastName} 'nın maç teklifini reddettiniz. ${selectedOffer.offererFirstName} ${selectedOffer.offererLastName} galip sayılacaktır.`,
          date: new Date(),
          status: "unread",
          subject:
            offerAction === "accept"
              ? "Maç Teklifi Kabul Edildi"
              : "Maç Teklifi Reddedildi",
        },
      ];

      // Bildirimleri kaydet
      for (const notification of notifications) {
        const userNotificationRef = doc(db, "users", notification.recipientId);
        const notificationsCollectionRef = collection(
          userNotificationRef,
          "notifications"
        );
        await addDoc(notificationsCollectionRef, {
          message: notification.message,
          date: notification.date,
          status: notification.status,
          subject: notification.subject,
        });
      }

      alert(
        offerAction === "accept"
          ? "Teklif başarıyla kabul edildi!"
          : "Teklif başarıyla reddedildi!"
      );
      setActiveStep(0); // İlk sayfaya dön
    } catch (error) {
      console.error(
        `Error ${offerAction === "accept" ? "accepting" : "rejecting"} offer:`,
        error
      );
    }
  };
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </div>
      </div>
    );

  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <div className="container mt-4">
      <Carousel
        activeIndex={activeStep}
        controls={false}
        indicators={false}
        interval={null}
      >
        {/* Step 1: Teklif Bilgileri */}
        <Carousel.Item>
          <div className="d-flex justify-content-center align-items-center vh-95 bg-dark text-white">
            <div className="text-center p-4">
              <h3>Gelen Teklifler</h3>
              {receivedOffers.length > 0 ? (
                <div>
                  {receivedOffers.map((offer) => (
                    <div key={offer.id} className="mb-3 border p-3 rounded">
                      <p>
                        Teklif Eden: {offer.offererFirstName}{" "}
                        {offer.offererLastName}
                      </p>
                      <p>
                        Teklif Tarihi:{" "}
                        {new Date(
                          offer.createdAt.seconds * 1000
                        ).toLocaleString("tr-TR")}
                      </p>
                      <p>Ligdeki Sıralama: {offer.offererRank}</p>
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => handleSelectOffer(offer)}
                      >
                        İncele
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Şu anda gelen bir teklif bulunmamaktadır.</p>
              )}
            </div>
          </div>
        </Carousel.Item>

        {/* Step 2: Kabul/Red Seçimi */}
        <Carousel.Item>
          <div className="d-flex justify-content-center align-items-center vh-95 bg-dark text-white">
            <div
              className="card bg-light text-dark p-4"
              style={{ width: "600px" }}
            >
              <div className="card-body">
                <h3 className="card-title text-center mb-4">
                  Teklifi Değerlendirin
                </h3>
                {selectedOffer && leagueDatas && (
                  <>
                    <div className="d-flex justify-content-around">
                      <div className="text-center">
                        <h5 className="card-subtitle mb-2">Teklif Eden</h5>
                        <p className="card-text">
                          {selectedOffer.offererFirstName}{" "}
                          {selectedOffer.offererLastName}
                        </p>
                        <p className="card-text">
                          Rank: {selectedOffer.offererRank}
                        </p>
                      </div>
                      <div className="text-center">
                        <h5 className="card-subtitle mb-2">Teklif Alan</h5>
                        <p className="card-text">
                          {userData.firstName} {userData.lastName}
                        </p>
                        <p className="card-text">
                          Rank: {userData.rank || "Bilinmiyor"}
                        </p>
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <p>
                        Teklif Tarihi:{" "}
                        {new Date(
                          selectedOffer.createdAt.seconds * 1000
                        ).toLocaleString("tr-TR")}
                      </p>
                      <p>
                        Teklifin Geçerlilik Süresi:{" "}
                        {leagueDatas.maxDaysToRespond} gün
                      </p>
                      <p>
                        Kalan Süre:{" "}
                        {Math.max(
                          0,
                          Math.ceil(
                            (new Date(
                              selectedOffer.createdAt.seconds * 1000
                            ).getTime() +
                              leagueDatas.maxDaysToRespond *
                                24 *
                                60 *
                                60 *
                                1000 -
                              Date.now()) /
                              (1000 * 60 * 60 * 24)
                          )
                        )}{" "}
                        gün
                      </p>
                      <button
                        className="btn btn-success me-3 mt-2"
                        onClick={() => handleActionSelection("accept")}
                      >
                        Kabul Et
                      </button>
                      <button
                        className="btn btn-danger mt-2"
                        onClick={() => handleActionSelection("reject")}
                      >
                        Reddet
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <button className="btn btn-secondary mt-3" onClick={handlePrevStep}>
              Geri
            </button>
          </div>
        </Carousel.Item>

        {/* Step 3: Son Onay Ekranı */}
        <Carousel.Item>
          <div className="d-flex justify-content-center align-items-center vh-95 bg-dark text-white">
            <div className="text-center p-4">
              <h3>Onay Ekranı</h3>
              {offerAction === "accept" ? (
                <>
                  <p>Teklifi kabul etmek üzeresiniz.</p>
                  <p>
                    Lig kurallarına göre maç en geç {leagueDatas?.maxDaysToPlay}{" "}
                    gün içinde oynanmalıdır.
                  </p>
                  <button
                    className="btn btn-success mt-3"
                    onClick={handleFinalConfirmation}
                  >
                    Onayla
                  </button>
                </>
              ) : (
                <>
                  <p>Teklifi reddetmek üzeresiniz. Bu durumda:</p>
                  <ul>
                    <li>Rakip oyuncu galip sayılacaktır.</li>
                    <li>
                      Rakibiniz sizin sıranıza yükselecek ve siz bir sıra
                      gerileyeceksiniz.
                    </li>
                  </ul>
                  <button
                    className="btn btn-danger mt-3"
                    onClick={handleFinalConfirmation}
                  >
                    Onayla
                  </button>
                </>
              )}
              <button
                className="btn btn-secondary mt-3"
                onClick={handlePrevStep}
              >
                Geri
              </button>
            </div>
          </div>
        </Carousel.Item>

        {/* Step 4: Sonuç Ekranı */}
        <Carousel.Item>
          <div className="d-flex justify-content-center align-items-center vh-95 bg-dark text-white">
            <div className="text-center p-4">
              <h3>Sonuç</h3>
              {offerAction === "accept" ? (
                <p>Teklif başarıyla kabul edildi!</p>
              ) : (
                <p>Teklif başarıyla reddedildi!</p>
              )}
              <button
                className="btn btn-secondary mt-3"
                onClick={() => setActiveStep(0)}
              >
                Ana Sayfaya Dön
              </button>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default MatchOfferReviewCarousel;
