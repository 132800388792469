// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDA2N2i2TJBE4sAaBugiK_4sDizpL0NbGs",
  authDomain: "tenisligi-782fd.firebaseapp.com",
  projectId: "tenisligi-782fd",
  storageBucket: "tenisligi-782fd.firebasestorage.app",
  messagingSenderId: "1082339649141",
  appId: "1:1082339649141:web:f3d6a8aaf92af549387a3c",
  measurementId: "G-EYD1KMFV6Y",
};
// Firebase App'i başlat
const app = initializeApp(firebaseConfig);

// Tarayıcıya özgü Firebase Analytics'i yükle
let analytics;
if (typeof window !== "undefined") {
  import("firebase/analytics")
    .then(({ getAnalytics, isSupported }) => {
      isSupported().then((supported) => {
        if (supported) {
          analytics = getAnalytics(app);
          console.log("Analytics başarıyla etkinleştirildi.");
        } else {
          console.warn("Analytics bu ortamda desteklenmiyor.");
        }
      });
    })
    .catch((error) => {
      console.error("Analytics modülü yüklenirken hata oluştu:", error);
    });
}
// Diğer Firebase servislerini başlat
export const auth = getAuth(app);
export const db = getFirestore(app); // Firestore yapılandırması
export default app;
