import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import ShowModal from "../component/ShowModal1";
import EditLeague from "../component/EditLeague";
import AddLeague from "../component/AddLeague";
import DeleteLeague from "../component/DeleteLeague";
import LeagueApplications from "../component/LeagueApplications";
import RequireAdmin from "../component/RequireAdmin";
import LeagueParticipants from "../component/LeagueParticipants";
import LeagueModerators from "../component/LeagueModerators"; // Yeni bileşen

function LeagueSettings({ userInfo }) {
  const [leagues, setLeagues] = useState([]);
  const [modal, setModal] = useState({ type: null, data: null });
  const [pendingCount, setPendingCount] = useState(0); //

  // Fetch leagues from Firestore
  const fetchLeagues = async () => {
    const leaguesSnapshot = await getDocs(collection(db, "leagues"));
    setLeagues(
      leaguesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  // Fetch pending applications count
  const fetchPendingApplicationsCount = async () => {
    const pendingQuery = query(
      collection(db, "leagueApplications"),
      where("status", "==", "Pending")
    );
    const pendingSnapshot = await getDocs(pendingQuery);
    setPendingCount(pendingSnapshot.size); // Bekleyen başvuruları say
  };

  useEffect(() => {
    fetchLeagues();
    fetchPendingApplicationsCount(); // Başvuru sayısını getir
  }, []);

  // Moderatör ekleme ve çıkarma işlemleri
  const addModerator = async (leagueId, userId) => {
    try {
      const moderatorsCollectionRef = collection(
        db,
        `leagues/${leagueId}/moderators`
      );
      await addDoc(moderatorsCollectionRef, { userId });
      // alert("Moderatör başarıyla eklendi!");
    } catch (error) {
      console.error("Moderatör eklenirken hata oluştu:", error);
      alert("Moderatör eklenirken bir hata oluştu.");
    }
  };

  const removeModerator = async (leagueId, moderatorId) => {
    try {
      const moderatorDocRef = doc(
        db,
        `leagues/${leagueId}/moderators`,
        moderatorId
      );
      await deleteDoc(moderatorDocRef);
      // alert("Moderatör başarıyla silindi!");
    } catch (error) {
      console.error("Moderatör silinirken hata oluştu:", error);
      alert("Moderatör silinirken bir hata oluştu.");
    }
  };

  // Modal Close Handler
  const closeModal = () => setModal({ type: null, data: null });
  const handleSuccess = () => {
    // console.log("Katılımcı başarıyla eklendi!");
    // Burada diğer işlemler yapılabilir, örneğin modal kapatma vb.
  };

  return (
    <RequireAdmin userInfo={userInfo}>
      <div className="container mt-4">
        <h2>League Settings</h2>

        {/* Add League Button */}
        <button
          className="btn btn-primary mb-2"
          onClick={() => setModal({ type: "add" })}
        >
          <i className="bi bi-plus-circle-fill"> Add League </i>
        </button>

        {/* League List */}
        <h3>Lig Listesi</h3>
        <ul className="list-group">
          {leagues.map((league) => (
            <li
              key={league.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {league.name}
              <div>
                <button
                  className="btn btn-outline-primary btn-sm me-3"
                  onClick={() => setModal({ type: "edit", data: league.id })}
                >
                  <i className="bi bi-pencil"></i>
                </button>
                <button
                  className="btn btn-outline-danger btn-sm me-3"
                  onClick={() => setModal({ type: "delete", data: league })}
                >
                  <i className="bi bi-trash"></i>
                </button>
                <button
                  className="btn btn-outline-secondary btn-sm me-3"
                  onClick={() => setModal({ type: "show", data: league.id })}
                >
                  <i className="bi bi-eye"></i>
                </button>
                <button
                  className="btn btn-outline-warning btn-sm"
                  onClick={() =>
                    setModal({
                      type: "moderators",
                      data: [league.id, league.name],
                    })
                  }
                >
                  <i className="bi bi-person-badge"></i> Moderatörler
                </button>
              </div>
            </li>
          ))}
        </ul>

        {/* Pending Applications Button */}
        <div className="mt-4">
          <button
            className="btn btn-info position-relative"
            onClick={() => setModal({ type: "applications" })}
          >
            View Pending League Applications
            {pendingCount > 0 && (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {pendingCount}
              </span>
            )}
          </button>
        </div>

        {/* Conditional Modals */}
        {modal.type === "add" && (
          <ShowModal title="Yeni Lig Ekle" onClose={closeModal}>
            <AddLeague
              onClose={closeModal}
              onSuccess={fetchLeagues}
              userInfo={userInfo}
            />
          </ShowModal>
        )}
        {modal.type === "edit" && (
          <ShowModal title="Ligi Düzenle" onClose={closeModal}>
            {}
            <EditLeague
              leagueId={modal.data}
              onClose={closeModal}
              onSuccess={closeModal}
              userInfo={userInfo}
            />
          </ShowModal>
        )}
        {modal.type === "delete" && (
          <ShowModal title="Lig Silme" onClose={closeModal}>
            <DeleteLeague
              league={modal.data}
              onClose={closeModal}
              onDeleteSuccess={fetchLeagues}
            />
          </ShowModal>
        )}
        {modal.type === "show" && (
          <ShowModal title="Lig Oyuncuları Görüntüleme" onClose={closeModal}>
            <LeagueParticipants
              league_id={modal.data}
              onClose={closeModal}
              onSuccess={handleSuccess}
            />
          </ShowModal>
        )}
        {modal.type === "moderators" && (
          <ShowModal title="Lig Moderatörleri" onClose={closeModal}>
            <LeagueModerators
              leagueId={modal.data[0]}
              leagueName={modal.data[1]}
              addModerator={addModerator}
              removeModerator={removeModerator}
              onClose={closeModal}
            />
          </ShowModal>
        )}
        {modal.type === "applications" && (
          <ShowModal title="League Applications" onClose={closeModal}>
            <LeagueApplications />
          </ShowModal>
        )}
      </div>
    </RequireAdmin>
  );
}

export default LeagueSettings;
