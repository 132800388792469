const SendMatchOffer = ({
  activeLeague,
  userId,
  onPrevStep,
  selectedOpponent,
  setOfferAccepted,
  offerAccepted,
  handleSendOffer,
}) => {
  if (!selectedOpponent) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger text-center">
          Bir hata oluştu! Lütfen teklif edilecek bir rakip seçin ve tekrar
          deneyin.
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-secondary" onClick={onPrevStep}>
            {"<"} Geri
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4 d-flex justify-content-center align-items-center">
      <div className="card shadow-lg p-4 w-100" style={{ maxWidth: "700px" }}>
        <h4 className="card-title text-center text-primary mb-4">
          {activeLeague.name} Liginde Maç Teklifi
        </h4>
        <div className="card-body">
          <div className="border p-3 rounded bg-light mb-4">
            <h5 className="text-secondary mb-3">Rakip Oyuncu:</h5>
            <p className="text-dark fw-bold">
              {selectedOpponent.firstName} {selectedOpponent.lastName}
            </p>
          </div>

          <div className="border p-3 rounded bg-light mb-4">
            <h5 className="text-secondary mb-3">Maç Kuralları:</h5>
            <ul className="list-group">
              <li className="list-group-item">
                Maç toplam <strong>{activeLeague.setCount}</strong> set
                üzerinden oynanacaktır.
              </li>
              <li className="list-group-item">
                Set beraberliğinde:{" "}
                <strong>
                  {activeLeague.setDecisionsFormat === "Super"
                    ? "Süper Tie-break"
                    : activeLeague.setDecisionsFormat === "Set"
                    ? "Karar Seti"
                    : "geçersiz format"}
                </strong>
                .
              </li>
              <li className="list-group-item">
                Puan beraberliğinde:{" "}
                <strong>
                  {activeLeague.pointType === "advantage"
                    ? "Avantaj Sayısı"
                    : activeLeague.pointType === "deciding"
                    ? "Karar Sayısı"
                    : "farketmez"}
                </strong>
                .
              </li>
              <li className="list-group-item">
                Oyun beraberliğinde:{" "}
                <strong>{activeLeague.setTieBreakScore}</strong> puanlık Set
                Tie-break oynanacaktır.
              </li>
              {activeLeague.setDecisionsFormat === "Super" && (
                <li className="list-group-item">
                  Süper Tie-break Sayısı:{" "}
                  <strong>{activeLeague.superTieBreak}</strong> sayı olarak
                  oynanacaktır.
                </li>
              )}
            </ul>
          </div>

          <div className="border p-3 rounded bg-light mb-4">
            <h5 className="text-secondary mb-3">Önemli Bilgiler:</h5>
            <ul className="list-group">
              <li className="list-group-item">
                Rakip oyuncu teklifinize{" "}
                <strong>{activeLeague.maxDaysToRespond}</strong> gün içinde
                cevap vermelidir.
              </li>
              <li className="list-group-item">
                Teklif kabul edildikten sonra maç{" "}
                <strong>{activeLeague.maxDaysToPlay}</strong> gün içinde
                oynanmalıdır.
              </li>
            </ul>
          </div>

          {/* Checkbox for accepting conditions */}
          <div className="form-check d-flex align-items-center justify-content-start mb-4">
            <input
              type="checkbox"
              className="form-check-input me-2"
              id="offerAcceptCheckbox"
              checked={offerAccepted}
              onChange={(e) => setOfferAccepted(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="offerAcceptCheckbox">
              Şartları kabul ediyorum
            </label>
          </div>

          {/* Action Buttons */}
          <div className="d-flex justify-content-between">
            <button className="btn btn-secondary" onClick={onPrevStep}>
              {"<"} Geri
            </button>
            <button
              className="btn btn-primary"
              disabled={!offerAccepted}
              onClick={handleSendOffer}
            >
              Teklifi Gönder
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMatchOffer;
