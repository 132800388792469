import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import app from "../firebase"; // Firebase'i içe aktar
import "bootstrap/dist/css/bootstrap.min.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Şifre en az 6 karakter olmalı
  };

  const handleRegister = async () => {
    setError("");
    setSuccess("");

    if (!validateEmail(email)) {
      setError("Geçersiz e-posta adresi.");
      return;
    }

    if (!validatePassword(password)) {
      setError("Şifre en az 6 karakter olmalıdır.");
      return;
    }

    const auth = getAuth(app); // Firebase uygulamasını kullan
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setSuccess("Kayıt başarılı! Giriş sayfasına yönlendiriliyorsunuz...");

      // 2 saniye bekleyip giriş sayfasına yönlendir
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError(
          "Bu e-posta adresi zaten kullanılıyor. Giriş yapmayı deneyin."
        );
      } else {
        setError("Bir hata oluştu: " + error.message);
      }
    }
  };

  return (
    <div className="container mt-15">
      <h2>Kayıt Ol</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <input
        type="email"
        className="form-control my-2"
        placeholder="E-posta"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        className="form-control my-2"
        placeholder="Şifre"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="btn btn-primary" onClick={handleRegister}>
        Kayıt Ol
      </button>
      <p className="mt-3">
        Zaten bir hesabınız var mı? <a href="/login">Giriş Yapın</a>
      </p>
      <p>
        <a href="/guesthome">Misafir Girişi</a>
      </p>
      {/* <button className="btn btn-primary mx-5" onClick={navigate("/login")}>
        Giriş Yap
      </button> */}
    </div>
  );
};

export default Register;
